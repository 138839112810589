import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { ApplicationsListService } from '../../../services/applications-list.service';
import { throttle } from 'lodash';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fadeAnimation } from '@app/app/animations/fade/fade.animation';
import { AuthState } from '@app/app/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';

interface SearchResult {
  borrower: any;
  primaryContactName: string;
  businessName: string;
  routerLink?: string;
}

@Component({
  selector: 'app-applications-search',
  templateUrl: './applications-search.component.html',
  styleUrls: ['./applications-search.component.scss'],
  animations: [fadeAnimation],
  standalone: false
})
export class ApplicationsSearchComponent implements OnInit {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary'
  @Output() searchViewClosed: EventEmitter<void> = new EventEmitter();
  newSearch = this._store.selectSignal(SaasFeaturesState.saasPermitted('businessSearch'));
  navV2 = this._store.selectSignal(AuthState.userHasPermission('NavigationV2DarkLaunch'));

  oldSearchTerm: string | number | boolean;
  oldHasSearched = false;

  searchResults: SearchResult[] = [];
  hasFailedSearch = false;
  searching = false;
  errored = false;
  searchControl: FormControl = new FormControl('');

  get searchTerm() {
    return this.searchControl.value;
  }

  constructor(private applicationsService: ApplicationsListService, private _store: Store) {
    this.search = throttle(this.search, 300);
    this.oldSearch = throttle(this.oldSearch, 300);

    this.searchControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(this.search.bind(this));
  }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
  }

  private _clearSearchField(): void {
    this.searchControl.setValue('');
  }

  private _closeSearchView(): void {
    this.searchViewClosed.emit();
  }

  oldSearch() {
    if (this.oldSearchTerm &&
      typeof this.oldSearchTerm === 'string' &&
      this.oldSearchTerm.length >= 3) {
      this.searching = true;
      this.applicationsService.searchApplications(this.oldSearchTerm).pipe(take(1)).subscribe((res) => {
        this.searching = false;
        this.oldHasSearched = true;
        this.errored = false;

        if (res.data && res.data.length === 0) {
          this.searchResults = [];
        } else {
          this.searchResults = res.data.map((result: any) => {
            const routerLink = this.newSearch() ? `businesses/${result?.id}` : `deal/${result?.dealId}`
            return {
              result,
              routerLink,
              primaryContactName: result.cname,
              businessName: result.name,
            }
          });
        }
      }, (res) => {
        this.searchResults = [];
        this.searching = false;
        this.errored = true;
        this.oldHasSearched = true;
      });
    }
  }

  search() {
    if (this.searchTerm &&
      typeof this.searchTerm === 'string' &&
      this.searchTerm.length >= 3) {
      this.searching = true;
      this.applicationsService.searchApplications(this.searchTerm).pipe(take(1)).subscribe({
        next: (res) => {
          this.searching = false;
          this.errored = false;

          if (res.data && res.data.length === 0) {
            this.hasFailedSearch = true;
            this.searchResults = [];
          } else {
            this.hasFailedSearch = false;
            this.searchResults = res.data.map((result: any) => {
              const routerLink = `deal/${result?.dealId}`
              return {
                ...result,
                routerLink,
                primaryContactName: result.cname,
                businessName: result.name,
              }
            });
          }
        },
        error: () => {
          this.searchResults = [];
          this.searching = false;
          this.errored = true;
          this.hasFailedSearch = true;
        }
      });
    } else {
      this.searchResults = [];
      this.searching = false;
      this.hasFailedSearch = false;
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }

  onInFieldClearButton(): void {
    return this.searchTerm
      ? this._clearSearchField()
      : this._closeSearchView();
  }

  onOptionSelected(): void {
    this._clearSearchField();
    this._closeSearchView();
  }
}
