import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fade', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('{{durationEnter}}ms {{delayEnter}}ms cubic-bezier(.4, .22, .31, 1.01)',
      style({
        opacity: `{{opacityPercent}}%`,
      })
    )
  ]),
  transition(':leave', [
    style({
      opacity: `{{opacityPercent}}%`,
    }),
    animate('{{durationLeave}}ms {{delayLeave}}ms cubic-bezier(.4, .22, .31, 1.01)',
      style({
        opacity: 0
      })
    )
  ], {
    params: {
      durationLeave: 300,
      durationEnter: 300,
      delayEnter: 300,
      delayLeave: 300,
      opacityPercent: '100%'
    }
  })
]) as AnimationMetadata;
