@if (navV2()) {
    <div class="sidenav-user-container">
        <button class="sidenav-user-menu-button"
                appMatchMenuWidth
                [trigger]="userMenuWidth"
                #userSettingsMenuTrigger="matMenuTrigger"
                [class.active]="userSettingsMenuTrigger.menuOpen"
                [matMenuTriggerFor]="userSettingsMenu"
                (menuOpened)="userSettingsMenuTrigger.menuOpen"
                (menuClosed)="userSettingsMenuTrigger.menuClosed">
            <mat-icon class="user-avatar-icon"
                      fontSet="material-icons-outlined">
                perm_identity
            </mat-icon>
            @if (userSettingsMenuTrigger.menuOpen) {
                <div class="sidenav-container-user-info"
                     [appSlideAnimation]="userSettingsMenuTrigger.menuOpen"
                     [slideEnterDurationInMs]="200"
                     [slideLeaveDurationInMs]="160">
                    <div class="sidenav-username">{{ userFullName }}</div>
                    <div class="sidenav-institution">{{ organizationName }} </div>
                </div>
            }
        </button>
        @if (saasFeatureBadgeEnabled()) {
            <app-saas-new-feature-badge></app-saas-new-feature-badge>
        }
        <mat-menu #userSettingsMenu="matMenu"
                  class="sidenav-user-menu-nav-v2"
                  yPosition="above"
                  xPosition="after">
            <div #userMenuWidth></div>
            <button mat-menu-item (click)="selectSettings()">
                <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                <span>settings</span>
            </button>
            <button mat-menu-item (click)="selectLogout()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
} @else {
    @if (collapsed || navMenuCollapsed) {
        <div class="sidenav-user-container">
            <button class="sidenav-user-menu-button"
                    #userSettingsMenuTrigger="matMenuTrigger"
                    [class.active]="userSettingsMenuTrigger.menuOpen"
                    [matMenuTriggerFor]="userSettingsMenu"
                    (menuOpened)="userSettingsMenuTrigger.menuOpen"
                    (menuClosed)="userSettingsMenuTrigger.menuClosed">
                <mat-icon class="user-avatar-icon" fontSet="material-icons-outlined">perm_identity</mat-icon>
            </button>
            <mat-menu #userSettingsMenu="matMenu"
                      [hasBackdrop]="true">
                <button mat-menu-item (click)="selectSettings()">
                    <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                    <span>Settings</span>
                </button>
                <button mat-menu-item (click)="selectLogout()">
                    <mat-icon>logout</mat-icon>
                    <span>Sign out</span>
                </button>
            </mat-menu>
        </div>
    } @else {
        <div class="sidenav-user-container">
            <button class="sidenav-user-menu-button"
                    #userSettingsMenuTrigger="matMenuTrigger"
                    [class.active]="userSettingsMenuTrigger.menuOpen"
                    [matMenuTriggerFor]="userSettingsMenu"
                    (menuOpened)="userSettingsMenuTrigger.menuOpen"
                    (menuClosed)="userSettingsMenuTrigger.menuClosed">
                <mat-icon class="user-avatar-icon"
                          fontSet="material-icons-outlined">
                    <span>perm_identity</span>
                </mat-icon>
                <div class="sidenav-container-user-info">
                    <span class="sidenav-username">{{ userFullName }}</span>
                    @if (organizationName) {
                        <span class="sidenav-institution">{{ organizationName }}</span>
                    }
                    @if (isSbaSpecialist) {
                        <div class="border rounded bg-lendio-yellow-400 text-xs px-2 my-1 font-semibold leading-tight">SBA Specialist</div>
                    }
                </div>
                <mat-icon class="user-settings-icon"
                          fontSet="material-icons-outlined">
                    <span>settings</span>
                </mat-icon>
            </button>
            @if (saasFeatureBadgeEnabled()) {
                <app-saas-new-feature-badge></app-saas-new-feature-badge>
            }
            <mat-menu #userSettingsMenu="matMenu"
                      [hasBackdrop]="true">
                <ng-template matMenuContent>
                    <div [style.width.px]="263">
                        <button mat-menu-item
                                (click)="selectSettings()">
                            <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                            <span>Settings</span>
                        </button>
                        <button mat-menu-item
                                (click)="selectLogout()">
                            <mat-icon>logout</mat-icon>
                            <span>Sign out</span>
                        </button>
                    </div>
                </ng-template>
            </mat-menu>
        </div>
    }
}
