import { State, Action, StateContext, Selector, Store, createSelector } from '@ngxs/store';
import { DocumentsActions as DA } from './documents.actions';
import { tap, catchError } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { Injectable } from '@angular/core';
import { Document } from '../../interfaces/document.model';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { DocumentCategory } from '@app/app/interfaces/document-category.model';
import { AddSnackbarError, AddSnackbarNotification, AddSnackbarSuccess } from '@app/app/store/snackbar/snackbar.actions';
import { PusherService } from '@app/app/services/pusher.service';
import { orderBy } from 'lodash';
import { DocumentRequirements } from '@app/app/interfaces/document-requirements.model';
import { DealsService } from '@app/app/services/deals.service';
import { DealDocument } from '@app/app/interfaces/deal-document.model';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
export class DocumentsStateModel {
  documents: Document[];
  current: Document | null;
  categories: DocumentCategory[];
  documentRequirements: DocumentRequirements;
}

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: {
    documents: [],
    current: null,
    categories: [],
    documentRequirements: {
      remaining: [],
      satisfied: []
    }
  }
})

@Injectable()

export class DocumentsState {

  @Selector()
  static documents(state: DocumentsStateModel) {
    return state.documents;
  }

  @Selector()
  static current(state: DocumentsStateModel) {
    return state.current;
  }

  @Selector()
  static categories(state: DocumentsStateModel) {
    return state.categories;
  }

  @Selector()
  static consumerCreditReportDocByContactId(contactId: number) {
    return createSelector([DocumentsState], (state) => {
      const creditDocsForContact = state.documents?.documents.filter( doc => {
        return doc.filename.startsWith(`c-${contactId}`);
      });
      return orderBy(creditDocsForContact, 'created', 'desc')[0];
    });
  }

  @Selector()
  static commercialCreditReportDoc(state: DocumentsStateModel) {
    const creditReportDocs = state.documents.filter(doc => {
      return !doc.filename.startsWith('c-') && doc.category === 'commercialCreditReport';
    });
    return orderBy(creditReportDocs, 'created', 'desc')[0];
  }

  @Selector()
  static documentRequirements(state: DocumentsStateModel) {
    return state.documentRequirements;
  }

  constructor(
    private documentsService: DocumentsService,
    private _dealsService: DealsService,
    private store: Store,
    private pusherService: PusherService,
    private _snackbarService: LendioSnackbarService
  ) {}

  // Get a single document by ID
  @Action(DA.GetDocument)
  getDocument(_: StateContext<DocumentsStateModel>, { documentId }: DA.GetDocument) {
    return this.documentsService.getDocument(documentId).pipe(
      catchError((error) => throwError(() => error)),
      tap((documentResponse) => this.store.dispatch(new DA.PatchDocumentState(documentResponse.data)))
    )
  }

  // Get borrower documents.
  @Action(DA.GetBorrowerDocuments)
  getIndex(
    ctx: StateContext<DocumentsStateModel>,
    { borrowerId, borrowerLenderId }: DA.GetBorrowerDocuments
  ) {
    return this.documentsService.getIndex(borrowerId).pipe(
      catchError(err => {
        this.store.dispatch(new AddSnackbarError({
          identifier: 'documentsFetchError',
          subTitle: `Unable to retrieve this business' documents. Please refresh the page to try again.`,
        }));
        ctx.patchState({
          documents: []
        });
        return throwError(err);
      }), tap(response => {
        const filteredDocs = borrowerLenderId ? this.filterDocs(response.data, borrowerLenderId) : response.data;
        const documents = filteredDocs.map(d => {
          return {
            ...d,
            created: d.createdTimestamp
          }
        })
        ctx.patchState({ documents });
      })
    );
  }

  // We support posting/uploading multiple documents. In practice, we'll just
  // call this async for each doc.
  @Action(DA.Post)
  post({ patchState, getState }: StateContext<DocumentsStateModel>, { file, formData }: DA.Post) {
    return this.documentsService.post(file, formData).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to create this document. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
        const created: number = response.data.createdTimestamp;
        let document = { ...response.data, created };
        const state = getState();

        // Add to current list of entities in store.
        const documents = [ document, ...state.documents ];
        patchState({ documents });
      })
    );
  }

  // Update a document.
  @Action(DA.Put)
  put({ patchState, getState }: StateContext<DocumentsStateModel>, payload: DA.Put) {
    const { document } = payload;
    return this.documentsService.put(document).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to update this document. Please refresh the page to try again.'
        })
        );
        return throwError(err);
      }),
      tap(response => {
        let document = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(d => {
          if (d.id === document.id) {
            return { ...document, created: document.createdTimestamp };
          } else {
            return d;
          }
        });
        patchState({
          documents,
          current: document
        });
      })
    );
  }

  @Action(DA.PatchDealDocument)
  patchDealDocument({ dispatch, getState, patchState }: StateContext<DocumentsStateModel>, { dealId ,documentUpdates }: DA.PatchDealDocument) {
    return this.documentsService.patchDealDocument(dealId, documentUpdates).pipe(
      catchError((err) => {
        // Some kind of thing to UI
        dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to edit document.'
        }))
        // TODO: Log error to Rollbar
        return throwError(() => err);
      }),
      tap((response) => {
        let document = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(d => {
          if (d.id === document.id) {
            return document;
          } else {
            return d;
          }
        });
        patchState({
          documents,
          current: document
        });
      })
    );
  }

  @Action(DA.Delete)
  delete(ctx: StateContext<DocumentsStateModel>, payload: DA.Delete) {
    const { id } = payload;
    return this.documentsService.delete(id).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to delete this document. Please refresh the page to try again.'
        })
        );
        return throwError(err);
      }),
      tap(() => {
        // Remove document in local store list and ensure current is null.
        const state = ctx.getState();
        const documents = state.documents.filter(d => d.id !== id);
        ctx.patchState({
          documents,
          current: null
        });
      }),
    );
  }

  @Action(DA.DeleteDealDocument)
  deleteDealDocument(ctx: StateContext<DocumentsStateModel>, { dealId, documentId }: DA.DeleteDealDocument) {
    return this.documentsService.deleteDealDocument(dealId, documentId).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to delete this document.'
        })
        );
        return throwError(() => err);
      }),
      tap(() => {
        const state = ctx.getState();
        const documents = state.documents.filter(d => d.id !== documentId);
        ctx.patchState({
          documents,
          current: null
        });
        this.store.dispatch(new DA.GetDocumentRequirements(dealId));
      }),
    );
  }

  // Get deal documents.
  @Action(DA.GetDealDocuments)
  getDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { dealId, borrowerLenderId }: DA.GetDealDocuments
  ) {
    return this.documentsService.getDocuments(dealId).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to retrieve documents for this deal. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
        const documents = borrowerLenderId ? this.filterDocs(response.data, borrowerLenderId) : response.data;
        ctx.patchState({ documents });
      })
    );
  }

  // Get deal documents for deal-grid / advanced documents management.
  @Action(DA.GetAdvancedDealDocuments)
  getAdvancedDealDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { dealId, borrowerLenderId }: DA.GetDealDocuments
  ) {
    return this.documentsService.getAdvancedDealDocuments(dealId).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to retrieve documents for this deal. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
        const documents = borrowerLenderId ? this.filterDocs(response.data, borrowerLenderId) : response.data;
        ctx.patchState({ documents });
      })
    );
  }

  @Action(DA.AcceptDealDocument)
  acceptDealDocument({ dispatch, getState, patchState }: StateContext<DocumentsStateModel>, { dealId, documentId }: DA.AcceptDealDocument) {
    return this.documentsService.acceptDealDocument(dealId, documentId).pipe(
      catchError((err) => {
        // Some kind of thing to UI
        dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to accept document.'
        }))
        // TODO: Log error to Rollbar
        return throwError(() => err);
      }),
      tap((response) => {
        let {
          documentId,
          accepted,
          acceptedBy,
          rejected,
          rejectedBy,
          rejectedReason: reason
        } = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(document => {
          if (document.id === documentId) {
            return {
              ...document,
              status: {
                accepted,
                acceptedBy,
                rejected,
                rejectedBy,
                reason
              }
            };
          } else {
            return document;
          }
        });
        patchState({
          documents
        });
        this.store.dispatch(new DA.GetDocumentRequirements(dealId));
      })
    );
  }

  @Action(DA.PostDocumentRequirements)
  postDocumentRequirements({ patchState, getState }: StateContext<DocumentsStateModel>, { dealId, documentRequirements }: DA.PostDocumentRequirements) {
    return this._dealsService.postDocumentRequirements(dealId, documentRequirements).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to create document requirements. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
          this.store.dispatch(new DA.GetDocumentRequirements(dealId));
          this.store.dispatch( new AddSnackbarSuccess({
            subTitle: "Requirement(s) successfully added."
          }));

          if (documentRequirements.notifyBorrower) {
            this.store.dispatch( new AddSnackbarSuccess({
              badge: {
                type: 'icon',
                value: 'mark_email_read',
                class: 'bg-lendio-green-400',
              },
              subTitle: "The new documents notification has been successfully sent to the borrower."
            }));
          }
      })
    );
  }

  @Action(DA.DeleteDocumentRequirement)
  deleteDocumentRequirement({ patchState, getState }: StateContext<DocumentsStateModel>, { dealId, documentRequirement }: DA.DeleteDocumentRequirement) {
    return this._dealsService.deleteDocumentRequirement(dealId, documentRequirement).pipe(
      catchError(err => {
        this.store.dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to delete document requirement. Please refresh the page to try again.'
        }));
        return throwError(err);
      }),
      tap(response => {
          const state = getState();
          patchState({
            documentRequirements: {
              satisfied: state.documentRequirements.satisfied,
              remaining: state.documentRequirements.remaining.filter(req => {
                return req.sourceId !== documentRequirement.sourceId;
              })
            }
          });
          //Note: we could go this route instead or both.
          // this.store.dispatch(new DA.GetDocumentRequirements(dealId));
          this.store.dispatch( new AddSnackbarNotification({
            subTitle: "Requirement removed.",
            identifier: `${Date.now()}`,
            dismissible: false
          }));
      })
    );
  }

  @Action(DA.RejectDealDocument)
  rejectDealDocument({ dispatch, getState, patchState }: StateContext<DocumentsStateModel>, { dealId, documentId, reason }: DA.RejectDealDocument) {
    return this.documentsService.rejectDealDocument(dealId, documentId, reason).pipe(
      catchError((err) => {
        // Some kind of thing to UI
        dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to reject document.'
        }))
        // TODO: Log error to Rollbar
        return throwError(() => err);
      }),
      tap((response) => {
        let {
          documentId,
          accepted,
          acceptedBy,
          rejected,
          rejectedBy,
          rejectedReason: reason
        } = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(document => {
          if (document.id === documentId) {
            return {
              ...document,
              status: {
                accepted,
                acceptedBy,
                rejected,
                rejectedBy,
                reason
              }
            };
          } else {
            return document;
          }
        });
        patchState({
          documents
        });

        // TODO: We should just patch state instead of re-fetching.
        this.store.dispatch(new DA.GetDocumentRequirements(dealId));
      })
    );
  }

  @Action(DA.ClearDealDocumentStatus)
  clearDealDocumentStatus({ dispatch, getState, patchState }: StateContext<DocumentsStateModel>, { dealId, documentId }: DA.ClearDealDocumentStatus) {
    return this.documentsService.clearDealDocumentStatus(dealId, documentId).pipe(
      catchError((err) => {
        // Some kind of thing to UI
        dispatch(new CreateNewAlert({
          level: 'error',
          message: 'Unable to clear document status.'
        }))
        // TODO: Log error to Rollbar
        return throwError(() => err);
      }),
      tap((response) => {
        let {
          documentId,
          accepted,
          acceptedBy,
          rejected,
          rejectedBy,
          rejectedReason: reason
        } = response.data;
        const state = getState();
        // Update within store and make current.
        const documents = state.documents.map(document => {
          if (document.id === documentId) {
            return {
              ...document,
              status: {
                accepted,
                acceptedBy,
                rejected,
                rejectedBy,
                reason
              }
            };
          } else {
            return document;
          }
        });
        patchState({
          documents
        });
        this.store.dispatch(new DA.GetDocumentRequirements(dealId));
      })
    );
  }

  // Clear out documents between borrowers/businesses or deals.
  @Action(DA.ClearDocumentsState)
  clearDocuments({ patchState }: StateContext<DocumentsStateModel>, {}: DA.ClearDocumentsState) {
    patchState({
      documents: [],
      documentRequirements: {
        remaining: [],
        satisfied: []
      }
    });
  }

  // Get the public/external document categories (Type in UI).
  @Action(DA.GetDocumentCategories)
  getDocumentCagegories({ patchState }: StateContext<DocumentsStateModel>) {
    return this.documentsService.getDocumentCategories().pipe(
      catchError(err => {
        return throwError(err);
      }),
      tap(response => {
        patchState( {
          categories: response.data
        })
      })
    )
  }

  /**
   * Filter business docs.
   *
   * @param Document[] documents Unfiltered doc list from api
   * @param number|null lenderId For filtering business docs
   */
  filterDocs(documents: Document[], lenderId: number) {
    const removeDocCategoryList = [
      'applicationUnsigned',
      'applicationSigned'
    ];
    const lendioLenderId = 44566;
    return lenderId === lendioLenderId
      ? documents
      : documents.filter( doc => !removeDocCategoryList.includes(doc.category));
  }

  @Action(DA.GetDocumentRequirements)
  getDocumentRequirements({ patchState }: StateContext<DocumentsStateModel>, { dealId }: DA.GetDocumentRequirements) {
    return this._dealsService.getDocumentRequirements(dealId).pipe(
      // TODO: Handle error?
      catchError((err) => throwError(() => err)),
      tap((documentRequirements) => {
        patchState({ documentRequirements });
      })
    );
  }

  @Action(DA.PatchDocumentState)
  patchDocumentState({ patchState, getState }: StateContext<DocumentsStateModel>, { document, dealDocument }: DA.PatchDocumentState) {
    const state = getState();
    let documents = state.documents;

    if (document) {
      documents = documents.map(d => {
        if (d.id === document?.id) {
          const doc = { ...document, created: document.createdTimestamp };
          if (d.status && !document.status) {
            doc.status = d.status;
          }
          return doc;
        } else {
          return d;
        }
      });

      if (!documents.find(d => d.id === document?.id)) {
        documents.push({ ...document, created: document.createdTimestamp });
      }
    } else if (dealDocument) {
      let doc = documents.find(d => d.id === dealDocument.documentId) ?? null;

      if (doc) {
        documents = documents.map(d => {
          if (d.id === doc.id) {
            return {
              ...doc,
              status: {
                rejected: dealDocument.rejected,
                rejectedBy: dealDocument.rejectedBy,
                accepted: dealDocument.accepted,
                acceptedBy: dealDocument.acceptedBy,
                reason: dealDocument.rejectedReason
              }
            }
          }

          return d;
        });
      }
    }

    patchState({
      documents
    });
  }

  @Action(DA.DeleteDocumentState)
  deleteDocumentState({ patchState, getState }: StateContext<DocumentsStateModel>, { documentId }: DA.DeleteDocumentState) {
    const state = getState();
    const documents = state.documents.filter(d => d.id !== documentId);

    patchState({
      documents
    });
  }

  @Action(DA.SubscribeToDocumentStateUpdates)
  subscribeToDocumentStateUpdates({ }: StateContext<DocumentsStateModel>, { borrowerId, dealId }: DA.SubscribeToDocumentStateUpdates) {
    this.pusherSubscribe(borrowerId, dealId);
  }

  @Action(DA.UnsubscribeFromDocumentStateUpdates)
  unsubscribeFromImportStateUpdates({ }: StateContext<DocumentsStateModel>, { borrowerId, dealId }: DA.UnsubscribeFromDocumentStateUpdates) {
    this.pusherUnsubscribe(borrowerId, dealId);
  }

  pusherSubscribe(borrowerId: number, dealId?: number) {
    this.pusherService.subscribe({
      name: `document-scan-status-is-clean-${borrowerId}`,
      auth: false,
      handler: (event: any, document: Document) => {
        if(event === 'document-scan-status-is-clean'){
          this.handlePusherMessage(document);
        }
        return;
      }
    });

    if (dealId) {
      this.pusherService.subscribe({
        name: `deal-documents-${dealId}`,
        auth: false,
        handler: (event: any, dealDocument: DealDocument) => {
          if(['deals-document-status-change', 'deals-document-deleted'].includes(event)){
            this.handleDealDocumentPusherMessage(event, dealDocument);
          }

          return;
        }
      });
    }
  }

  pusherUnsubscribe(borrowerId: number, dealId?: number) {
    this.pusherService.unsubscribe({
      name: `document-scan-status-is-clean-${borrowerId}`,
      auth: false,
      handler: () => {}
    });

    if (dealId) {
      this.pusherService.unsubscribe({
        name: `deal-documents-${dealId}`,
        auth: false,
        handler: () => {}
      });
    }
  }

  handlePusherMessage(document: Document) {
    this.store.dispatch(new DA.GetDocument(document.id));
  }

  handleDealDocumentPusherMessage(event: string, dealDocument: DealDocument) {
    this.store.dispatch(new DA.GetDocumentRequirements(dealDocument.dealId));
    if (event === 'deals-document-status-change') {
      this.store.dispatch(new DA.PatchDocumentState(null, dealDocument));
    }

    if (event === 'deals-document-deleted') {
      this.store.dispatch(new DA.DeleteDocumentState(dealDocument.documentId));
    }
  }
}
