@if (navV2()) {
    <button class="no-label-list-item"
            mat-list-item
            (click)="toggle()">
        <div class="flex flex-col justify-center items-center">
            <div class="lendio-nav-item-state-layer">
                <mat-icon fontSet="material-icons-outlined"
                          [matBadge]="!!(count$ | async) ? '.' : ''"
                          matBadgeSize='small'
                          class="green-badge">
                    <span>notifications</span>
                </mat-icon>
            </div>
        </div>
    </button>
} @else {
    <button mat-icon-button
            (click)="toggle()">
        <mat-icon color="accent"
                  [class.notification-badge-empty]="!!(count$ | async)"
                  fontSet="material-icons-outlined">
            <span>notifications_active</span>
        </mat-icon>
    </button>
}
