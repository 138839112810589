@if (canSeeAdvDocManagement) {
    <app-documents-grid data-cy="advanced-deal-documents-component"
                        [borrowerId]="borrowerId"
                        [dealId]="dealId" 
                        [loanProductCategory]="loanProductCategory"
                        [documents]="documents$ | async">
    </app-documents-grid>
} @else {
    <app-documents-table [borrowerId]="borrowerId" [dealId]="dealId" />
}
