<div class="documents-header-container flex items-center justify-between py-3 border-b border-solid border-lendio-warm-gray-100">
    <div class="flex flex-row items-center ml-4">
        <div class="flex flex-row items-center text-lg">
            <div class="tex-lendio-warm-gray-900 font-semibold">Documents</div>
            <div class="text-lendio-warm-gray-400 ml-1">
                ({{ documents.length }})
            </div>
        </div>
        <!-- Bulk actions -->
        @if (selectedRows.length) {
            <div [@fadeInOut] class="flex flex-row items-center mx-3 lendio-density-1">
                <button mat-stroked-button 
                        color="'accent'" 
                        class="flex flex-row items-center mx-1"
                        (click)="handleDownload($event)">
                    <span class="text-lendio-warm-gray-900">Download</span>
                </button>
                <button mat-stroked-button
                        color="'accent'" 
                        [matTooltip]="!canDelete ? 'You do not have delete permissions.' : ''" 
                        matTooltipPosition="below"
                        matTooltipShowDelay="500"
                        [disabled]="!canDelete"
                        class="flex flex-row items-center mx-1"
                        (click)="handleDelete($event)">
                    <span class="text-lendio-warm-gray-900">Delete</span>
                </button>
            </div>
        }
    </div>
    <!-- Upload -->
    <div class="flex items-center justify-between mr-4 lendio-density-1">
        @if (canUpload) {
            <button mat-stroked-button 
                    color="'accent'"
                    matTooltipShowDelay="500"
                    class="flex flex-row items-center"
                    (click)="handleUpload()">
                <mat-icon class="text-lendio-warm-gray-900 mr-2 material-icons-outlined" iconPositionStart>file_upload</mat-icon>
                <span class="text-lendio-warm-gray-900">Upload</span>
            </button>
        }
    </div>
</div>
