import { Injectable } from '@angular/core';
import { DebtScheduleItem } from '@app/app/interfaces/debt-schedule-item.struct';
import { DebtScheduleService } from '@app/app/services/dscr/debt-schedule.service';
import {
  CreateDebtScheduleItem,
  GetDebtScheduleItems,
  UpdateDebtScheduleItem
} from '@app/app/store/debt-schedule/debt-schedule.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface DebtScheduleStateModel {
  debtScheduleItems: DebtScheduleItem[];
  loading: boolean;
}

@State<DebtScheduleStateModel>({
  name: 'DebtSchedule',
  defaults: {
    loading: false,
    debtScheduleItems: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class DebtScheduleState {

  @Selector()
  static debtScheduleItems(state: DebtScheduleStateModel): DebtScheduleItem[] {
    return state?.debtScheduleItems || [];
  }

  @Selector()
  static loading( state: DebtScheduleStateModel ) {
    return state.loading;
  }

  constructor(
    private debtScheduleService: DebtScheduleService,
  ) {}

  @Action(GetDebtScheduleItems)
  GetDebtScheduleItems(
    ctx: StateContext<DebtScheduleStateModel>,
    { borrowerId }: GetDebtScheduleItems,
  ) {
    ctx.patchState({loading: true});
    return this.debtScheduleService.getDebtScheduleItems(borrowerId)
      .pipe(
        catchError(err => {
          ctx.patchState({
            loading: false,
          });
          throw err;
        }),
        tap(({ data }) => {
          ctx.patchState({
            debtScheduleItems: data,
            loading: false,
          });
        })
      );
  }

  @Action(CreateDebtScheduleItem)
  CreateDebtScheduleItem(
    ctx: StateContext<DebtScheduleStateModel>,
    { borrowerId, debtScheduleItem }: CreateDebtScheduleItem,
  ) {
    ctx.patchState({loading: true});
    return this.debtScheduleService.createDebtScheduleItem(borrowerId, debtScheduleItem)
      .pipe(
        catchError(err => {
          ctx.patchState({
            loading: false,
          });
          throw err;
        }),
        tap( ({data}) => {
          ctx.setState(patch({
            debtScheduleItems: append([data as DebtScheduleItem]),
            loading: false,
          }));
        })
      );
  }

  @Action(UpdateDebtScheduleItem)
  UpdateDebtScheduleItem(
    ctx: StateContext<DebtScheduleStateModel>,
    { borrowerId, debtScheduleItem }: UpdateDebtScheduleItem,
  ) {
    ctx.patchState({loading: true});
    return this.debtScheduleService.updateDebtScheduleItem(borrowerId, debtScheduleItem)
      .pipe(
        catchError(err => {
          ctx.patchState({
            loading: false,
          });
          throw err;
        }),
        tap( updatedItem => {
          ctx.setState(patch({
            debtScheduleItems: updateItem(
              existingItem => existingItem.id === updatedItem.id,
              patch({
                ...updatedItem,
              })),
            loading: false,
          }));
        })
      );
  }
}
