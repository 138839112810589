import { DebtScheduleItem } from '@app/app/interfaces/debt-schedule-item.struct';

export class GetDebtScheduleItems {
  static readonly type = `[DebtSchedule] GetDebtScheduleItems`;
  constructor(
    public borrowerId: number,
  ) {}
}

export class CreateDebtScheduleItem {
  static readonly type = `[DebtSchedule] CreateDebtScheduleItem`;
  constructor(
    public borrowerId: number,
    public debtScheduleItem: DebtScheduleItem,
  ) {}
}

export class UpdateDebtScheduleItem {
  static readonly type = `[DebtSchedule] UpdateDebtScheduleItem`;
  constructor(
    public borrowerId: number,
    public debtScheduleItem: DebtScheduleItem,
  ) {}
}
