import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentRequirementItem } from '@app/app/interfaces/document-requirement-item.model';
import { LAST_24_MONTHS_SELECT_OPTIONS } from "@app/app/components/document-upload/document-upload-constants";

@Component({
    selector: 'document-requirement-list-item',
    standalone: true,
    templateUrl: './document-requirement-list-item.component.html',
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule
    ]
})
export class DocumentRequirementListItem {
    @Input() documentRequirement: DocumentRequirementItem;
    @Output() onTimeClassification = new EventEmitter<{ documentRequirementItem: DocumentRequirementItem, index: number }>();
    @Output() onRemove = new EventEmitter<number>();
    @Input() index: number;

  last24Months: { display: string, value: string, parts: { month: number, year: number } }[] = LAST_24_MONTHS_SELECT_OPTIONS;
  years: string[] = [];

  constructor() {
      this.years = Array.from({ length: 10 }, (v, i) => {
        const year = new Date().getFullYear() - i;
        return year.toString();
      });
    }

    updateMonthString({ value }) {
        this.onTimeClassification.emit({ documentRequirementItem: { ...this.documentRequirement, monthValues: value }, index: this.index });
    }

    updateYearString({ value }) {
        this.onTimeClassification.emit({ documentRequirementItem: { ...this.documentRequirement, yearsString: value }, index: this.index });
    }

    remove() {
        this.onRemove.emit(this.index);
    }
}
