import { StateOperator } from '@ngxs/store';
import {
  compose,
  iif,
  insertItem,
  patch,
  Predicate,
  updateItem
} from '@ngxs/store/operators';
import { NoInfer } from '@ngxs/store/operators';

export function upsertItem<T>(
  selector: number | Predicate<T>,
  upsertValue: Record<string, any>
): StateOperator<T[]> {
  return compose<T[]>(
    items => <T[]>(items || []),
    iif<T[]>(
      items => Number(selector) === selector,
      iif<T[]>(
        items => Number(selector) < items.length,
        updateItem(selector, patch(upsertValue) as StateOperator<T>),
        insertItem(upsertValue as NoInfer<T>, <number>selector),
      ),
      iif<T[]>(
        items => items.some(<Predicate<T>>selector),
        updateItem(selector, patch(upsertValue) as StateOperator<T>),
        insertItem(upsertValue as NoInfer<T>),
      ),
    ),
  );
}

