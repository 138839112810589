import { ProfitLossStatementItem } from '@app/app/interfaces/profit-loss-statement-item.struct';

export class GetProfitLossStatementItems {
  static readonly type = `[ProfitLoss] GetProfitLossStatements`;
  constructor(
    public borrowerId: number,
  ) {}
}

export class UpsertProfitLossStatementItems {
  static readonly type = `[ProfitLoss] CreateProfitLossStatementItem`;
  constructor(
    public borrowerId: number,
    public profitLossStatementItems: Partial<ProfitLossStatementItem>[],
  ) {}
}
