<section data-cy="document-requirements-component" class="document-requirements" [class.no-requirements]="!hasRequirements()">
    <mat-expansion-panel expanded="true" class="no-padding mat-elevation-z0 !rounded-lg" [disabled]="!hasRequirements()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex flex-row items-center">
                    <span class="mr-1">Requirements</span>
                    @if (canAddDocumentRequirements()) {
                        <button data-cy="add-document-requirements-button" (click)="openDocumentRequirementDialog($event)"
                                class="h-full"
                        >
                            <mat-icon class="text-lg leading-relaxed">add</mat-icon>
                        </button>
                    }
                </div>
                <!-- Accepted fraction -->
                <p class="accepted-fraction text-xs font-normal !mb-0 text-lendio-warm-gray-500">{{ acceptedFraction() }}</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- List -->
        @if (hasRequirements()) {
            <ul class="pt-2 pb-4">
                @for (remainingDocument of documentRequirements().remaining; track $index) {
                    <li data-cy="document-requirement"
                        class="group text-xs pl-8 py-1 flex items-center justify-between border rounded-lg border-dashed border-white" 
                        [ngClass]="{'hover:bg-lendio-warm-gray-10 hover:border-lendio-warm-gray-100' : remainingDocument.source === 'documentRequest' }">
                    <span>
                        {{ remainingDocument.documentCategory?.name }}
                            @if (remainingDocument.timePeriod) {
                                - {{ remainingDocument.timePeriod }}
                            }
                    </span>
                    <button data-cy="delete-doc-requirement-button"
                            (click)="handleRequirementDelete($event, remainingDocument)" 
                            [ngClass]="{'group-hover:visible': remainingDocument.source === 'documentRequest'}" 
                            class="invisible mr-2">
                        <mat-icon class="leading-none text-lendio-warm-gray-100">delete_outlined</mat-icon>
                    </button>
                    </li>
                }
                @for (acceptedDocument of documentRequirements().satisfied; track $index) {
                    <li class="text-xs pl-2 pb-6 last:pb-0 flex">
                        <mat-icon class="accepted">check_small</mat-icon>
                        {{ acceptedDocument.documentCategory?.name }}
                        @if (acceptedDocument.timePeriod) {
                            - {{ acceptedDocument.timePeriod }}
                        }
                    </li>
                }
            </ul>
        }
    </mat-expansion-panel>
</section>