import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { PageTitleDataService } from '@app/app/services/page-title-data.service';
import { Select } from '@ngxs/store';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from "@app/app/store/auth/auth.state";

export interface Link {
  name: string;
  label: string;
  visible: Observable<boolean>;
  link: string;
}

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  standalone: false
})
export class AdministrationComponent implements OnInit, OnDestroy {
  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;
  @Select(SaasFeaturesState.featuresConfig) featuresConfig$: Observable<any>;
  private _torpedo$ = new Subject<void>();
  routerEventSub: Subscription = new Subscription();
  importModuleActive: boolean;
  importDetailsActive$: Observable<boolean> = this._pageTitleService.getImportDetailsActive();
  apiModuleActive: boolean;
  user: AuthUser | undefined;

  constructor(private _pageTitleService: PageTitleDataService) {
    this.currentUser$.pipe(takeUntil(this._torpedo$)).subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this._pageTitleService.setTitleData({title: 'Administration'});
  }

  ngOnDestroy(): void {
    this.routerEventSub.unsubscribe();
    this._torpedo$.next();
    this._torpedo$.complete();
  }

  get userCanViewDemoTab(): boolean {
    return this.user?.permittedTo('lpxViewDemoTab') ?? false;
  }
}
