import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appFadeAnimation]'
})
export class FadeAnimationDirective implements OnChanges {
  @Input({alias: 'appFadeAnimation'}) fadeAnimation: boolean | undefined;
  @Input() fadeEnterDurationInMs: number = 300;
  @Input() fadeLeaveDurationInMs: number = 300;
  @Input() fadeEnterDelayInMs: number = 0;
  @Input() fadeLeaveDelayInMs: number = 0;
  @Input() fadeOpacityPercent: number = 100;
  state!: boolean;

  @HostBinding('@fade')
  get fade() {
    return {
      value: this.state,
      params: {
        durationEnter: this.fadeEnterDurationInMs,
        durationLeave: this.fadeLeaveDurationInMs,
        delayEnter: this.fadeEnterDelayInMs,
        delayLeave: this.fadeLeaveDelayInMs,
        opacityPercent: this.fadeOpacityPercent
      }
    };
  }

  ngOnChanges() {
    this.state = !this.state;
  }
}
