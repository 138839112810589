@if (navV2()) {
    <div class="flex flex-row items-start gap-2">
        <div class="lendio-density-1">
            <mat-form-field class="hijack-search-input lendio-no-hint-wrapper"
                            [@expandInput]="hijackSearchVisible ? 'expanded' : 'collapsed'"
                            appearance="fill">
                <mat-icon matPrefix
                          color="accent"
                          [matMenuTriggerFor]="partnersMenu"
                          #partnersTrigger="matMenuTrigger">
                    <span>search</span>
                </mat-icon>
                <input
                    [(ngModel)]="inputText"
                    (keyup)="search()"
                    data-cy="hijack-input"
                    placeholder="Search for a partner"
                    autocomplete="off"
                    matInput
                    type="text">
                <button mat-icon-button
                        class="hijack-close-button"
                        matSuffix
                        (click)="onInFieldCloseButton()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <mat-menu #partnersMenu="matMenu" yPosition="below">
            @if (searchResults && searchResults.hasOwnProperty('data')) {
                @for(result of searchResults.data; track result; let i = $index) {
                    <button mat-menu-item
                            [matMenuTriggerFor]="usersMenu"
                            #usersTrigger="matMenuTrigger"
                            (mouseenter)="partnerHovered({ id: result.id, type: result.type}); $event.stopPropagation(); $event.preventDefault()"
                            (click)="$event.stopPropagation(); $event.preventDefault()"
                            [attr.data-cy]="'partner-' + i">
                        {{ result.name }} --
                    </button>
                }
            }
        </mat-menu>

        <mat-menu #usersMenu="matMenu"
                  xPosition="before"
                  [class.hidden]="!retrievingUsers && !retrievedUsers">
            @if (retrievingUsers) {
                <div class="text-center pl-10 pt-3">
                    <app-dot-loader-scale></app-dot-loader-scale>
                </div>
            }

            @if (users && !retrievingUsers && retrievedUsers) {
                @for (user of users.data; track user; let i = $index) {
                    <button mat-menu-item
                            (click)="hijack(user.id)"
                            [attr.data-cy]="'partner-user-' + i">
                        {{ user.first }} {{ user.last }}
                    </button>
                }
            }
            @if (!retrievingUsers && (!users || !users.data || users.data.length === 0)) {
                <button mat-menu-item disabled>No users found</button>
            }
        </mat-menu>
        <button mat-icon-button
                class="toolbar-icon-button hijack"
                [matTooltip]="hijackSearchVisible ? '' : 'Search partners'"
                matTooltipPosition="before"
                (click)="toggleSearchField()">
            <mat-icon class="toolbar-icon">business</mat-icon>
        </button>
    </div>
} @else {

<div class="lendio-density-1">
    <mat-form-field appearance="outline"
                    color="accent">
         <mat-icon matPrefix
                   color="accent"
                   [matMenuTriggerFor]="partnersMenu"
                   #partnersTrigger="matMenuTrigger">
             <span>search</span>
        </mat-icon>
        <input
            [(ngModel)]="inputText"
            (keyup)="search()"
            data-cy="hijack-input"
            placeholder="Search for a partner"
            autocomplete="off"
            matInput
            type="text">
    </mat-form-field>

    <mat-menu #partnersMenu="matMenu"
              yPosition="below">
        <ng-container *ngIf="searchResults && searchResults.hasOwnProperty('data')">
            <button
                mat-menu-item
                [matMenuTriggerFor]="usersMenu"
                #usersTrigger="matMenuTrigger"
                (mouseenter)="partnerHovered({ id: result.id, type: result.type}); $event.stopPropagation(); $event.preventDefault()"
                *ngFor="let result of searchResults.data; let i = index"
                (click)="$event.stopPropagation(); $event.preventDefault()"
                attr.data-cy="partner-{{i}}">
                {{ result.name }}
            </button>
        </ng-container>
    </mat-menu>

    <mat-menu #usersMenu="matMenu"
              xPosition="before"
              [class.hidden]="!retrievingUsers && !retrievedUsers">
        <ng-container *ngIf="retrievingUsers">
            <div class="text-center pl-10 pt-3">
                <app-dot-loader-scale></app-dot-loader-scale>
            </div>
        </ng-container>
        <ng-container *ngIf="users && !retrievingUsers && retrievedUsers">
            <button
                mat-menu-item
                *ngFor="let user of users.data; let i = index"
                (click)="hijack(user.id)"
                attr.data-cy="partner-user-{{i}}">
                {{ user.first }} {{ user.last }}
            </button>
        </ng-container>
        <ng-container *ngIf="!retrievingUsers && (!users || !users.data || users.data.length === 0)">
            <button
                mat-menu-item
                disabled>
                No users found
            </button>
        </ng-container>
    </mat-menu>
</div>
}
