import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

export const slideAnimation = trigger('slide', [
  transition(':enter', [
    style({
      width: 0,
    }),
    animate('{{durationEnter}}ms cubic-bezier(.4, .22, .31, 1.01)',
      style({
        width: '*',
      })
    )
  ]),
  transition(':leave', [
    style({
      width: '*',
    }),
    animate('{{durationLeave}}ms cubic-bezier(.4, .22, .31, 1.01)',
      style({
        width: 0,
      })
    )
  ])
]) as AnimationMetadata;
