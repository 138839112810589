import { DialogRef } from '@angular/cdk/dialog';
import { Component, computed, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ProfitLossStatementItem } from '@app/app/interfaces/profit-loss-statement-item.struct';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { UpsertProfitLossStatementItems } from '@app/app/store/profit-loss/profit-loss.actions';
import { Store } from '@ngxs/store';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { DebtScheduleItemDialogComponent } from '../debt-schedule-item-dialog/debt-schedule-item.dialog.component';

@Component({
  selector: 'app-dscr-edit-dialog',
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  providers: [provideNgxMask()],
  templateUrl: './dscr-edit-dialog.component.html',
  styleUrl: '../dscr-tab.component.scss'
})
export class DscrEditDialogComponent {

  borrowerId = this.store.selectSignal( BusinessState.businessId );
  formGroup = computed(() => {
    const items = [
      ...this.data.personalTaxData,
      ...this.data.businessTaxData,
    ];

    const structure = items.reduce((aggregate, item) => {
      return {
        ...aggregate,
        [item.alias]: new FormControl(item.profitLossStatementFieldValue),
      };
    }, {});

    return new FormGroup(structure);
  });

  title = computed(() => {
    const year = this.data?.year;
    const dscrYearString = year ? `${year} ` : '';
    return `Adjust or add to ${dscrYearString}DSCR to override`;
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: DialogRef<DebtScheduleItemDialogComponent>,
    private store: Store,
  ) {}

  save(): void {
    const items = [
      ...this.data.personalTaxData,
      ...this.data.businessTaxData,
    ];
    const formGroup = this.formGroup();
    const changed = items.filter( item => formGroup.controls[item.alias]?.dirty );
    const data = changed.map( changedItem => {
      return {
        ...changedItem,
        profitLossStatementFieldValue: formGroup.controls[changedItem.alias].value,
      };
    }) as ProfitLossStatementItem[];

    this.store.dispatch(new UpsertProfitLossStatementItems(this.borrowerId() as number, data) );
    this.dialogRef.close();
  }
}
