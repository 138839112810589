import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThoughtspotLiveboardModel } from '@app/app/interfaces/thoughtspot-liveboard.model';
import { PageTitleDataService } from '@app/app/services/page-title-data.service';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { ThoughtspotReportsState } from '@app/app/store/thoughtspot-reports/thoughtspot-reports.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
  standalone: false
})
export class ReportsListComponent implements OnInit, OnDestroy {
  constructor(private titleService: PageTitleDataService, private store: Store) {}

  pageDisplayState = 'loading';

  ts503ServiceUnavailable: boolean = false;
  fetchReportListComplete: boolean = false;
  tsAuthenticated: boolean | null = null;
  tsReports: ThoughtspotLiveboardModel[] = [];
  error: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Select(ThoughtspotReportsState.reportList)
  tsReports$: Observable<ThoughtspotLiveboardModel[]>;

  @Select(ThoughtspotReportsState.ts503ServiceUnavailable)
  tsServiceUnavailable$: Observable<boolean>;

  @Select(ThoughtspotReportsState.fetchReportListComplete)
  fetchReportListComplete$: Observable<boolean>;

  @Select(ThoughtspotReportsState.tsAuthenticated)
  tsAuthenticated$: Observable<boolean>

  @Select(ThoughtspotReportsState.error)
  error$: Observable<boolean>

  ngOnInit(): void {
    this.titleService.setTitleData({title: 'Reports'});

    this.tsReports$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.tsReports = x;
        this.updatePageDisplay();
      });

    this.tsServiceUnavailable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.ts503ServiceUnavailable = x;
        this.updatePageDisplay();
      });

    this.fetchReportListComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.fetchReportListComplete = x;
        this.updatePageDisplay();
      })

    this.tsAuthenticated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.tsAuthenticated = x;
        this.updatePageDisplay();
      })

    this.error$
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.error = x;
        this.updatePageDisplay();
        if (this.error) {
          this.store.dispatch(new CreateNewAlert(
            {
              level: 'error',
              message: 'Unable to fetch reports. Please refresh the page to try again.',
            }
          ));
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updatePageDisplay() {
   this.pageDisplayState = this.calculatePageDisplayState(
      this.ts503ServiceUnavailable,
      this.fetchReportListComplete,
      this.tsAuthenticated,
      this.tsReports,
    )
  }

  calculatePageDisplayState(
    ts503ServiceUnavailable: boolean,
    fetchReportListComplete: boolean,
    tsAuthenticated: boolean | null,
    tsReports: ThoughtspotLiveboardModel[],
  ) {

    if (ts503ServiceUnavailable) {
      return '503';
    }
    if (tsAuthenticated == null) {
      return 'loading';
    }
    if (tsAuthenticated && !fetchReportListComplete) {
      return 'loading';
    }
    if (tsAuthenticated == false) {
      return 'empty';
    }
    if (tsReports?.length > 0) {
      return 'reportsTable';
    }
    return 'empty';
  }
}
