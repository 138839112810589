import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';
import { ProfitLossStatementItem } from '@app/app/interfaces/profit-loss-statement-item.struct';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfitLossService {
  constructor(private http: HttpClient) { }

  getProfitLossStatementItems(borrowerId: number): Observable<{ data: ProfitLossStatementItem[] }> {
    return this.http.get<{ data: ProfitLossStatementItem[] }>(`${environment.apiUrl}/partner-portal/borrower/${borrowerId}/profit-loss-statement-items`)
  }

  upsertProfitLossStatementItems(
    borrowerId: number,
    profitLossStatementItems: Partial<ProfitLossStatementItem>[]): ObsLendioResponse<ProfitLossStatementItem[]> {
    return this.http.put<LendioResponse<ProfitLossStatementItem[]>>(`${environment.apiUrl}/partner-portal/borrower/${borrowerId}/profit-loss-statement-items`, {profitLossStatementItems});
  }
}
