import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DebtScheduleItem } from '@app/app/interfaces/debt-schedule-item.struct';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebtScheduleService {

  static debtTypeOptions = [
    {
      label: 'Credit Card',
      value: 'creditCard',
    },
    {
      label: 'Invoice Financing',
      value: 'invoiceFinancing',
    },
    {
      label: 'Term Loan',
      value: 'termLoan',
    },
    {
      label: 'Line of Credit',
      value: 'lineOfCredit',
    },
    {
      label: 'Shareholder Note',
      value: 'shareholderNote',
    },
  ];

  static paymentFrequencyOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Semi-monthly', value: 'semiMonthly' },
    { label: 'Monthly', value: 'monthly' },
  ];

  constructor(private http: HttpClient) { }

  getDebtScheduleItems(borrowerId: number): ObsLendioResponse<DebtScheduleItem[]> {
    return this.http.get<LendioResponse<DebtScheduleItem[]>>(`${environment.apiUrl}/borrower/${borrowerId}/debt-schedule-items`);
  }

  updateDebtScheduleItem(borrowerId: number, debtScheduleItem: DebtScheduleItem): Observable<DebtScheduleItem> {
    return this.http.put<DebtScheduleItem>(
      `${environment.apiUrl}/partner-portal/borrower/${borrowerId}/debt-schedule-items/${debtScheduleItem.id}`, debtScheduleItem
    );
  }

  createDebtScheduleItem(borrowerId: number, debtScheduleItem: DebtScheduleItem): ObsLendioResponse<DebtScheduleItem> {
    return this.http.post<LendioResponse<DebtScheduleItem>>(`${environment.apiUrl}/partner-portal/borrower/${borrowerId}/debt-schedule-items`, debtScheduleItem);
  }
}
