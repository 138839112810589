<div class="sidebar" appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
    @if ((applicationDetails$ | async); as applicationDetails) {
    <div class="grid gap-2">
        <div class="grid gap-4 sidebar-child">
            @if (isSbaSpecialist$ | async) {
                <div class="border border-solid border-gray-300 py-2 px-4 items-center rounded-lg font-semibold flex flex-row justify-between">
                    <div>Show deal to lender</div>
                    <mat-slide-toggle 
                        class="lendio-small-slide-toggle"
                        disableRipple
                        name="sbaVisible"
                        id="sba-lender-visible-toggle"
                        [checked]="isSbaVisible"
                        (change)="handleSbaVisibility()">
                    </mat-slide-toggle>
                </div>
            }
            <!--    DEAL STATUS CHANGE DROPDOWN    -->
            @if (permittedToManuallyChangeStatus$ | async) {
            <app-deal-status-change-dropdown class="{{showPushToLaserProButton$ ? 'mb-1 ' : 'mb-2'}}" data-cy="deal-status-change-dropdown"
                [deal]="applicationDetails.deal">
            </app-deal-status-change-dropdown>
            } @else {
            <!--    MAKE OFFER/DECLINE APP BUTTONS w/STATUS DISPLAY    -->
            <lendio-status-display [status]="{
                                value: applicationDetails.deal.status,
                                display: formatStatus(applicationDetails.deal.status),
                                stage: applicationDetails.deal.stage,
                                hidden: false,
                                disabledReason: null
            }">
            </lendio-status-display>
            @if (shouldDisplayMakeOfferBtn || shouldDisplayDeclineBtn) {
            <div class="py-2 lendio-density-1 flex flex-row gap-4">
                @if (shouldDisplayMakeOfferBtn) {
                <button mat-flat-button color="primary" class="flex-1" (click)="changeApplicationDetailTab('offers')">
                    Make offer
                </button>
                }
                @if (shouldDisplayDeclineBtn) {
                <button mat-stroked-button class="flex-1" (click)="openDeclineDialog()">
                    Decline app
                </button>
                }
            </div>
            }
            }

            <!--    SEND/RESEND AAN BUTTON    -->
            @if (showAANButton$ | async) {
            <button mat-flat-button class="mb-1" [color]="'primary'" (click)="reviewAAN()">
                {{ (applicationDetails.deal?.hasAdverseActionNotice || aanSent) ? 'Resend AAN' : 'Send AAN' }}
            </button>
            }

            <!--    RETRY DECISIONING BUTTON    -->
            @if (showRetryDecisioningButton$ | async) {
            <button mat-flat-button class="mb-1" [color]="'primary'"
                [disabled]="!(isEligbleForDecisioningRetry$ | async) && !(decisioningFailed$ | async)"
                (click)="retryDecisioningClicked.emit()">
                Retry Decisioning
            </button>
            }

            <!--    OVERRIDE/EDIT OVERRIDES BUTTON    -->
            @if (showOverrideButton$ | async) {
            <button mat-stroked-button class="mb-1" class="overrides-button" (click)="overrideButtonClicked.emit()">
                {{ (decisioningHasOverrides$ | async) ? 'Edit overrides' : 'Override' }}
            </button>
            }

            <!--    LASER PRO BUTTON    -->
            @if (showPushToLaserProButton$ | async) {
            <button mat-flat-button class="mb-3" color="primary"
                    [disabled]="(laserProProcessing$ | async) && !(laserProFailed$ | async)"
                    (click)="pushToLaserProButtonClicked.emit()">
                {{
                    (laserProProcessing$ | async) ? 'LaserPro Processing' :
                    (laserProFailed$ | async) ? 'Retry Push to LaserPro' :
                    'Push to LaserPro'
                }}
            </button>
            }

            <!--    DEAL ASSIGNMENT DROPDOWN    -->
            <div *ngIf="portalUsers$ | async">
                <app-assignment-dropdown data-cy="app-assignment-dropdown" [usersAssigned]="assignmentsArray || []"
                    [dealId]="applicationDetails.deal.id" [portalUsers]="portalUsers$ | async">
                </app-assignment-dropdown>
            </div>
        </div>
        <!--    DOCUMENT REQUIREMENTS SECTION    -->
        @if (canSeeAdvDocMgmt()) {
            <div class="sidebar-child">
                <app-document-requirements></app-document-requirements>
            </div>
        }

        <!--    OFFER    -->
        <div class="sidebar-child pt-8">
            <h2>Offer</h2>
            <div>Offer sent</div>
            <div data-cy="sidebar-offer-sent" class="text-sm font-normal mb-2">
                {{
                (mostRecentOffer?.created
                | date : "MMMM d, y 'at' h:mma") || "---"
                }}
            </div>

            <div>Offer expires</div>
            <div data-cy="sidebar-offer-expires" class="text-sm font-normal mb-2">
                {{
                (mostRecentOffer?.expiration
                | date : "MMMM d, y 'at' h:mma") || "---"
                }}
            </div>

            <div>Offer accepted</div>
            <div data-cy="sidebar-offer-accepted" class="text-sm font-normal mb-6">
                {{
                (mostRecentOffer?.accepted
                | date : "MMMM d, y 'at' h:mma") || "---"
                }}
            </div>
        </div>

        <!--    HISTORY    -->
        <div class="sidebar-child divider">
            <app-deal-activities-quick-view
                (openDealHistoryButtonClicked)="openDealHistoryButtonClicked.emit()"></app-deal-activities-quick-view>
        </div>

        <!--    DOCUMENTS    -->
        <div class="sidebar-child divider">
            <app-documents-quick-view></app-documents-quick-view>
        </div>

        <!--    ESIGNATURE STATUS   -->
        <div *ngIf="(hasInstantContractsFeature$ | async) && ((signers$ | async)?.length > 0)"
            class="sidebar-child divider">
            <app-esign-status [signers]="(signers$ | async)" [dealId]="dealId">
            </app-esign-status>
        </div>

        <!--    NOTES    -->
        <div class="border-t-4 sidebar-child divider">
            <app-deal-notes [dealId]="applicationDetails?.deal?.id"></app-deal-notes>
        </div>

        <!--    LENDIO REPS   -->
        @if (lendioOwnsBorrower) {
        <div class="border-t-4 sidebar-child divider">
            <app-lendio-rep [applicationDetails]="applicationDetails$ | async"></app-lendio-rep>
        </div>
        }

        <!--    FUNDING DESK    -->
        <!-- TODO: Remove this entire section when the new "Messages" tab gets out of Early Access and is live for everyone -->
        @if (lendioOwnsBorrower && !(canSeeMessagesTab$ | async)) {
        <div class="sidebar-child divider">
            <app-funding-desk-smart [dealId]="dealId" [isSidebar]="true"></app-funding-desk-smart>
        </div>
        }
    </div>
    }
</div>
