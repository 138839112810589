import { Component, OnInit, Input } from '@angular/core';
import { AuthState } from '@app/app/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { PageTitleDataService } from "@app/app/services/page-title-data.service";

@Component({
  selector: 'app-data-list-forgiveness-smart',
  templateUrl: './data-list-forgiveness-smart.component.html',
  styleUrls: ['./data-list-forgiveness-smart.component.scss'],
  standalone: false
})
export class DataListForgivenessSmartComponent implements OnInit {
  @Input() data: object[];
  @Input() title: string;

  displayedColumns: string[] = ['status', 'title', 'type', 'uploadedBy', 'borrowersUploaded', 'created'];

  constructor(
    private store: Store,
    private _pageTitleService: PageTitleDataService
  ) { }

  ngOnInit(): void {
    this._pageTitleService.setTitleData({title: 'Forgiveness'});
    if (this.store.selectSnapshot(AuthState.user)?.permittedTo('canManageLoanForgivenessCSV')) {
      this.displayedColumns.splice(0, 0, 'lender');
    }
  }
}
