import { Component, EventEmitter, inject, OnInit, Output, Signal } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { SearchableSelect } from '../../searchable-select/searchable-select.component';
import { DocumentCategory } from '@app/app/interfaces/document-category.model';
import { DocumentRequirementListItem } from './document-requirement-list-item/document-requirement-list-item.component';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { Store } from '@ngxs/store';
import { DocumentRequirementItem } from '@app/app/interfaces/document-requirement-item.model';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'app-document-requirement-dialog',
  templateUrl: './document-requirement-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIcon,
    MatDialogModule,
    SearchableSelect,
    DocumentRequirementListItem,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    ReactiveFormsModule,
    QuillModule
  ]
})
export class DocumentRequirementDialogComponent implements OnInit {
  @Output() onSave = new EventEmitter<{ documentRequirements: (DocumentCategory | DocumentRequirementItem)[], noticeToBorrower: string | null, notifyBorrower: boolean }>();
  @Output() onCancel = new EventEmitter<void>();
  selectExpanded: boolean = false;
  selectedCategories: (DocumentCategory | DocumentRequirementItem)[] = [];
  selectionsValidated: boolean;
  categories: Signal<DocumentCategory[]>;
  notifyBorrower: boolean = false;
  noticeToBorrower: string;

  quillFormControl = new FormControl('',
    [
      Validators.minLength(17), // Really 10 chars, because 7 are the wrapping paragraph tags
      Validators.maxLength(25 * 1024 * 1024) // Roughly 25MB which is Gmail’s limit
    ]
  );
  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['image'],
    ]
  };

  constructor(
    public store: Store,
    public selfDialogRef: MatDialogRef<DocumentRequirementDialogComponent>,
  ) {
        this.categories = this.store.selectSignal(DocumentsState.categories);
  }

  ngOnInit(): void {
    this.store.dispatch(new DA.GetDocumentCategories())
  }

  onlyBorrowerRestrictedCategoriesSelected(): boolean {
    return this.selectedCategories.every((category) => category.restrictions.includes('borrower'));
  }

  closeSelectExpansion(data: DocumentCategory[] | null) {
    if (data) {
      this.selectedCategories = [ ...this.selectedCategories, ...data ].map(value => this.updateValidation(value));
    }

    this.selectExpanded = false;
    this.calculateSelectedValidation();
  }

  openSelectExpansion() {
    this.selectExpanded = true;
  }

  handleTimeClassification({ documentRequirementItem, index }) {
      this.selectedCategories[index] = this.updateValidation(documentRequirementItem);
      this.calculateSelectedValidation();
  }

  handleRequirementRemoval(index: number) {
    this.selectedCategories = this.selectedCategories.filter((category, i) => i !== index);
  }

  save() {
    const addedRequirementPayload = {
      documentRequirements: this.selectedCategories,
      noticeToBorrower: this.notifyBorrower ? this.quillFormControl.value : null,
      notifyBorrower: this.notifyBorrower
    };

    this.selfDialogRef.close();
    this.onSave.emit(addedRequirementPayload);
    this.selfDialogRef.close();
  }

  cancel($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }

  calculateSelectedValidation() {
    this.selectionsValidated = this.selectedCategories.every(category => !category.validationError);
  }

  updateValidation(item) {
    const timeClassification = item.timeClassification;

    const validationError = timeClassification ?
        (
          timeClassification === 'month' ?
            (item.monthValues ?
                undefined :
                'Month required'
            ) :
            (
              item.yearsString ?
              undefined :
              'Year required'
            )
        ) : null;


    return {
        ...item,
        monthValues: item.monthValues,
        yearsString: item.yearsString,
        validationError
    };
  }

  trackByCategory(index: number, category: DocumentRequirementItem) {
    return category.id;
  }

  shouldDisableSaveButton(): boolean {
    const hasValidSelections = this.selectionsValidated && this.selectedCategories.length > 0;

    if (this.notifyBorrower) {
      return !this.quillFormControl.valid || !hasValidSelections;
    }

    return !hasValidSelections;
  }
}
