<div class="dscr-dialog-top">
    <div class="dscr-title">{{title()}}</div>
    <button mat-dialog-close class="dscr-dialog-close">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    @if (data.businessTaxData?.length) {
        <div class="dscr-title-2 my-2">Tax Data (business)</div>
        <div class="dscr-dialog-fields lendio-density-1">
            @for (item of data.businessTaxData; track $index) {
                <mat-form-field appearance="fill"
                                class="dscr-dialog-form-field"
                                floatLabel="always">
                    <mat-label>{{ item.label }}</mat-label>
                    <input matInput
                           placeholder="Not provided"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="$"
                           [dropSpecialCharacters]="true"
                           [value]="item?.profitLossStatementFieldValue"
                           [formControl]="formGroup().controls[item.alias]">
                </mat-form-field>
            }
        </div>
    }

    @if (data.personalTaxData?.length && !data.isCurrentYear) {
        <div class="dscr-title-2 my-2">Tax Data (personal)</div>
        <div class="dscr-dialog-fields lendio-density-1">
            @for (item of data.personalTaxData; track $index) {
                <mat-form-field appearance="fill"
                                class="dscr-dialog-form-field"
                                floatLabel="always">
                    <mat-label>{{ item.label }}</mat-label>
                    <input matInput
                           placeholder="Not provided"
                           mask="separator.2"
                           thousandSeparator=","
                           prefix="$"
                           [dropSpecialCharacters]="true"
                           [value]="item.profitLossStatementFieldValue"
                           [formControl]="formGroup().controls[item.alias]">
                </mat-form-field>
            }
        </div>
    }

</div>
<div mat-dialog-actions align="end" class="dscr-dialog-actions">
    <button mat-button color="primary" mat-dialog-close>Back</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
</div>
