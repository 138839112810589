import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appSlideAnimation]'
})
export class SlideAnimationDirective implements OnChanges {
  @Input('appSlideAnimation') slideAnimation!: boolean;
  @Input() slideEnterDurationInMs: number = 300;
  @Input() slideLeaveDurationInMs: number = 300;
  state!: boolean;
  startWidth!: number;

  constructor(private _slideElementRef: ElementRef) { }
  @HostBinding('@slide')
  get slide() {
    return {
      value: this.state,
      params: {
        startWidth: this.startWidth,
        durationEnter: this.slideEnterDurationInMs,
        durationLeave: this.slideLeaveDurationInMs
      }
    };
  }

  setStartWidth() {
    this.startWidth = this._slideElementRef.nativeElement.clientWidth;
  }

  ngOnChanges() {
    this.setStartWidth();
    this.state = !this.state;
  }
}
