import { StatusOption } from '@app/app/interfaces/status-option';
import { Label } from '../../interfaces/label.model';

export class GetApplicationDetails {
    static readonly type = '[ApplicationDetails] GetApplicationDetails';
    constructor(public dealId: number) {}
}

export class GetApplicationFields {
    static readonly type = '[ApplicationDetails] GetApplicationFields';
    constructor(public dealId: number) {}
}

export class ClearApplicationDetailsStore {
    static readonly type = '[ApplicationDetails] ClearApplicationDetailsStore';
    constructor() {}
}

export class ResetSbaStatus {
    static readonly type = '[ApplicationDetails] ResetSbaStatus';
    constructor() {}
}

export class DetachApplicationLabel {
    static readonly type = '[ApplicationDetails] DetachApplicationLabel';
    constructor(public labelId: number, public dealId: number) {}
}

export class AttachApplicationLabel {
    static readonly type = '[ApplicationDetails] AttachApplicationLabel';
    constructor(public label: Label, public dealId: number) {}
}

export class ChangeDealStatus {
  static readonly type = '[ApplicationDetails] ChangeDealStatus';
  constructor(
    public dealId: number,
    public status: string,
    public fund: boolean = false,
  ) {}
}

export class UpdateDealStatus {
  static readonly type = '[ApplicationDetails] UpdateDealStatus';
  constructor(
    public dealId: number,
    public statusOption: StatusOption,
    public reason: string,
    public otherReason: string,
  ) {}
}

// Assign/Unassign actions are duplicated in applications-list and here in
// details since old/new theme (as of 1/8/24) work differently.
export class AssignUserToDeal {
  static readonly type = '[ApplicationDetails] AssignUserToDeal';
  constructor(
    public dealId: number,
    public userId: number,
  ) {}
}

export class UnassignUserFromDeal {
  static readonly type = '[ApplicationDetails] UnassignUserFromDeal';
  constructor(
    public dealId: number,
    public userId: number,
  ) {}
}

export class CreateDealFlag {
  static readonly type = '[ApplicationDetails] CreateDealFlag';
  constructor(
    public dealId: number,
    public flag: string,
  ) {}
}

export class DeleteDealFlags {
  static readonly type = '[ApplicationDetails] DeleteDealFlags';
  constructor(
    public dealId: number,
    public flag: string,
  ) {}
}


