import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { RecentSearch, SearchResult } from '@app/app/interfaces/search/search-result.model';
import {
  ClearRecentSearches,
  ClearSearchResults,
  GetRecentSearchLocalStorage,
  Search
} from '@app/app/store/search/search.actions';
import { SearchState } from '@app/app/store/search/search.state';
import { Store } from '@ngxs/store';
import { debounce } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'search-input',
  templateUrl: 'search-input.component.html',
  standalone: false
})
export class SearchInputComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput', {read: MatAutocompleteTrigger}) searchInput: MatAutocompleteTrigger;

  private minSearchLength = 2;

  destroyed$ = new Subject<boolean>();
  errored$: Observable<boolean> = this.store.select( SearchState.error );
  storeLoading$: Observable<boolean> = this.store.select( SearchState.selectorFromKey('loading') );
  results$: Observable<SearchResult[]>;
  recentSearches$: Observable<RecentSearch[]> = this.store.select( SearchState.recentSearches );
  noResultsState$: Observable<boolean>;
  showRecentSearchState$: Observable<boolean>;
  mockingSearchLoading$ = new BehaviorSubject<boolean>(false);
  loadingState$: Observable<boolean>;

  searchTerm: string = '';
  mockSearchLoadingTimeout;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit():void {
    this.store.dispatch( new GetRecentSearchLocalStorage() );
    this.search = debounce( () => this._search(), 400);

    this.results$ = this.store.select( SearchState.searchResults ).pipe(
      takeUntil(this.destroyed$),
      map( results => {
        const formattedResults = !!results?.length ? results.map( result =>{
            const title = this.highlightSearchTerm(this.searchTerm, result.title);
            return {
              ...result,
              title,
            }
          }) : [];
          return formattedResults;
        }
      )
    );

    this.loadingState$ = combineLatest([this.storeLoading$, this.mockingSearchLoading$]).pipe(
      takeUntil(this.destroyed$),
      map(([storeLoading, mockingSearchLoading]) => storeLoading || mockingSearchLoading),
    )

    this.noResultsState$ = combineLatest([this.results$, this.loadingState$]).pipe(
      takeUntil(this.destroyed$),
      map(([results, loadingState]) => !results?.length && !loadingState && !!this.searchTerm?.length),
    );

    this.showRecentSearchState$ = combineLatest([
      this.recentSearches$,
      this.loadingState$,
      this.results$,
      this.mockingSearchLoading$,
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([recentSearches, loadingState, results, mockingSearchLoading]) => {
        return !!recentSearches?.length
          && (!loadingState && !mockingSearchLoading)
          && !results?.length
      }),
    );

    this.results$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(() => this.cancelMockingSearchLoading() );
  }

  highlightSearchTerm(searchTerm: string, inputString: string = ''): string {
    const escapedSearchTerm = searchTerm?.replace(/[<>=.*+?^${}()|[\]\\]/g, '\\$&'),
      regex = new RegExp(escapedSearchTerm, 'gi');

    return inputString?.replace(regex, '<b>$&</b>') || '';
  }

  handleKeyup($event: KeyboardEvent) {
    const enterKey = $event.key === 'Enter',
      escapeKey = $event.key === 'Escape';

    if(escapeKey) {
      this.resetSearch();
    }

    if(!!this.searchTerm?.length) {
      this.mockSearchLoading();
    }

    return (enterKey && !!this.searchTerm?.length) || this.searchTerm?.length > this.minSearchLength
      ? this.search()
        : !this.searchTerm?.length
        ? this.clearSearchResults()
          : false;
  }

  resetSearch() {
    this.cancelMockingSearchLoading();
    this.clearSearchResults(true);
    setTimeout(() => this.searchInput.openPanel(), 200 );
  }

  handleRecentSearchClick(term: string) {
    this.searchTerm = term;
    this.search();
    this.mockSearchLoading();
  }

  handleResultClick(businessId) {
    if(!!businessId) {
      this.clearSearchResults(true);
      this.router.navigateByUrl(`/businesses/${businessId}`);
    }
  }

  mockSearchLoading(): void {
    this.mockingSearchLoading$.next(true);
    this.searchInput.openPanel();
    clearTimeout(this.mockSearchLoadingTimeout);
    this.mockSearchLoadingTimeout = setTimeout(() => {
      this.cancelMockingSearchLoading();
    }, 1000);
  }

  cancelMockingSearchLoading(): void {
    clearTimeout(this.mockSearchLoadingTimeout);
    this.mockingSearchLoading$.next(false);
  }

  clearSearchResults(emptySearchTerm: boolean = false): void {
    this.store.dispatch( new ClearSearchResults() );
    emptySearchTerm ? this.searchTerm = '' : false;
  }

  clearRecentSearches() {
    this.store.dispatch( new ClearRecentSearches() );
  }

  search: () => void;

  _search() {
    !!this.searchTerm?.length ? this.store.dispatch( new Search(this.searchTerm) ) : this.clearSearchResults();
    this.searchInput.openPanel();
  }

  ngOnDestroy():void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.mockingSearchLoading$.complete();
  }
}
