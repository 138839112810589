<div class="dscr-dialog-top">
    <div class="dscr-title">{{ titleVariant() }} Debt schedule item</div>
    <button mat-dialog-close class="dscr-dialog-close">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="grid auto-rows-auto gap-x-6 grid-cols-2 pt-2 lendio-density-1">
            <mat-form-field appearance="fill">
                <mat-label>Lender/Debt Name</mat-label>
                <input matInput formControlName="lenderName" [type]="'text'">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select class="dscr-debt-schedule-select" formControlName="debtType">
                    @for (option of debtTypeOptions; track option.label) {
                        <mat-option [value]="option.value">{{ option.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Loan Start Date</mat-label>
                <input matInput formControlName="loanStartDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Term</mat-label>
                <input matInput formControlName="term" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Term Type</mat-label>
                <mat-select class="dscr-debt-schedule-select" formControlName="paymentFrequency">
                    @for (option of frequencyOptions; track option.label) {
                        <mat-option [value]="option.value">{{ option.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Interest Rate</mat-label>
                <input matInput formControlName="interestRate" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill" class="dscr-dialog-form-field">
                <mat-label>Original Balance</mat-label>
                <input matInput
                       formControlName="originalBalance"
                       mask="separator.2"
                       thousandSeparator=","
                       prefix="$"
                       [dropSpecialCharacters]="true">
            </mat-form-field>

            <mat-form-field appearance="fill" class="dscr-dialog-form-field">
                <mat-label>Current Balance</mat-label>
                <input matInput
                       formControlName="currentBalance"
                       mask="separator.2"
                       thousandSeparator=","
                       prefix="$"
                       [dropSpecialCharacters]="true">
            </mat-form-field>

            <mat-form-field appearance="fill" class="dscr-dialog-form-field">
                <mat-label>Payment Amount</mat-label>
                <input matInput
                       formControlName="paymentAmount"
                       mask="separator.2"
                       thousandSeparator=","
                       prefix="$"
                       [dropSpecialCharacters]="true">
            </mat-form-field>
        </div>

        <div class="column-grid justify-around my-2">
            <mat-checkbox formControlName="isDebtConsolidation">
                <mat-label>Is Debt Consolidation</mat-label>
            </mat-checkbox>

            <mat-checkbox formControlName="isSBALoan">
                <mat-label>Is SBA Loan</mat-label>
            </mat-checkbox>
        </div>
    </form>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="dscr-dialog-actions">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="formGroup.invalid">Save</button>
</div>
