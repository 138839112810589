@if (navV2()) {
    <div class="sidenav-search-view-results lendio-density-1">
        <mat-form-field class="sidenav-search-view-input"
                        appearance="fill">
            <mat-icon matPrefix class="sidenav-search-view-icon">search</mat-icon>
            <input matInput
                   type="text"
                   placeholder="Search..."
                   aria-label="Input"
                   [formControl]="searchControl">
            <div class="lendio-density-2" matSuffix>
                <button mat-icon-button
                        class="sidenav-search-view-close-button"
                        (click)="onInFieldClearButton()">
                    <mat-icon class="sidenav-search-view-close-icon">close</mat-icon>
                </button>
            </div>
        </mat-form-field>
        @for (result of searchResults; track result) {
            <button class="sidenav-search-view-option"
                    [routerLink]="result.routerLink"
                    (click)="onOptionSelected()">
                <div class="flex flex-row justify-between items-center">
                    <div class="sidenav-search-view-option-name">{{ result?.businessName }}</div>
                    <!--
                    todo - status is not part of the payload yet but these styles are ready to use in the theme
                    @if (result.deal?.status) {
                        <div class="sidenav-search-view-status-display">
                            <div class="sidenav-search-view-status-indicator {{ result.deal?.status }}"></div>
                            <div class="sidenav-search-view-status">
                                {{ result?.deal?.status | kebabToSentence }}
                            </div>
                        </div>
                    }
                    -->
                </div>
                <div class="sidenav-search-view-option-description">{{ result?.primaryContactName }}</div>
            </button>
        } @empty {
            @if (searchTerm && hasFailedSearch) {
                <div class="sidenav-search-view-empty-state"
                     [appFadeAnimation]
                     [fadeOpacityPercent]="100"
                     [fadeEnterDurationInMs]="250"
                     [fadeEnterDelayInMs]="0"
                     [fadeLeaveDurationInMs]="250"
                     [fadeLeaveDelayInMs]="0">
                    We couldn’t find any Businesses or Deals matching your search.
                </div>

                <div class="sidenav-search-view-tips"
                     [appFadeAnimation]
                     [fadeOpacityPercent]="100"
                     [fadeEnterDurationInMs]="250"
                     [fadeEnterDelayInMs]="0"
                     [fadeLeaveDurationInMs]="250"
                     [fadeLeaveDelayInMs]="0">
                    <div class="sidenav-search-view-tips-header">
                        <mat-icon fontSet="material-icons-outlined">lightbulb_2</mat-icon>
                        Tips for better results:
                    </div>
                    <div class="sidenav-search-view-tip">
                        You can search by business name, deal name, or relevant keywords.
                    </div>
                    <div class="sidenav-search-view-tip">
                        Try using partial terms if you’re unsure of the full name.
                    </div>
                </div>
            }
        }
    </div>
} @else {
    <div class="lendio-density-1">
        <mat-form-field color="accent"
                        appearance="outline">
            <mat-icon matPrefix
                      [color]="errored ? 'warn' : 'accent'"
                      [matTooltip]="errored ? 'There was an error while attempting to search for applications. Please try again.' : ''"
                      matTooltipPosition="below">
                <span>{{ errored ? 'warning' : 'search' }}</span>
            </mat-icon>
            <input [(ngModel)]="oldSearchTerm"
                   [matAutocomplete]="results"
                   (keyup)="oldSearch(); oldHasSearched = false;"
                   (focus)="searchResults = []; oldSearchTerm = ''; oldHasSearched = false"
                   placeholder="Search..."
                   autocomplete="off"
                   matInput
                   type="text">
            <mat-autocomplete #results>
                <!-- Loading results -->
                <mat-option disabled *ngIf="searching">
                    <app-dot-loader-scale></app-dot-loader-scale>
                </mat-option>

                <!-- We have results -->
                <mat-option
                    (click)="searchResults = []; oldHasSearched = false"
                    *ngFor="let result of searchResults"
                    [routerLink]="result.routerLink">
                    <div>{{ result?.businessName }}</div>
                    <div class="text-xs text-gray-400">{{ result?.primaryContactName }}</div>
                </mat-option>

                <!-- No results -->
                <mat-option disabled *ngIf="!searching && !searchResults && oldHasSearched && searchTerm">
                    No results for '{{ searchTerm }}'
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
}

