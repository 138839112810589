import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Business, BusinessAccessLevel } from '@app/app/interfaces/business.model';
import { PageTitleDataService } from '@app/app/services/page-title-data.service';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { Store, Select } from '@ngxs/store';
import { Observable, take, takeUntil, Subject } from 'rxjs';
import { EditBusinessDialogComponent } from './edit-business-dialog/edit-business-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { NaicsSearchService } from '@app/app/services/naics-search.service';
import { LendioSnackbarService } from "@app/app/services/lendio-snackbar.service";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  standalone: false
})

/**
 * Business Details - Overview tab.
 */
export class OverviewComponent implements OnInit, OnDestroy {

  // This is the business/borrower ID.
  @Select(BusinessState.business) business$: Observable<Business>;
  @Input() id: number;
  loading$ = this.store.select(BusinessState.loading);
  naicsCodeLabel: string;
  currentBusiness: Business | null;
  private destroyed$ = new Subject<void>();

  constructor(
    private store: Store,
    private titleService: PageTitleDataService,
    private dialog: MatDialog,
    private formOptionsService: FormOptionsService,
    private cdRef: ChangeDetectorRef,
    // Use to get the label for the naics code.
    private naicsSearch: NaicsSearchService,
    private _snackbarService: LendioSnackbarService
  ){}

  ngOnInit(): void {
    this.business$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe( business => {
      this.currentBusiness = business;
      this.setNaicsLabel();
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  // Open the edit dialog.
  openEditDialog(section: string, business: Business) {
    const dialogRef = this.dialog.open(EditBusinessDialogComponent, {
      width: '960px',
      data: { id: this.id, section, business },
      disableClose: true,
      autoFocus: false
    });

    // Show snackbar confirming save success.
    dialogRef.componentInstance.onSaveSuccess.subscribe(() => {
      // Grab the current (before refreshing from save) code has changed.
      let oldNaicsCode: string | undefined  = this.currentBusiness?.borrowerValues['670'].value ?? '';

      // Now get the refreshed business and do other stuff.
      const message = this.currentBusiness
        ? 'Changes to ' + this.currentBusiness.name + ' have been saved.'
        : 'Changes have been saved.';

      this._snackbarService.open({
        message,
        canDismiss: true,
        duration: 3000,
      });

      // Now potentially change this after the snackbar shows.
      this.setNaicsLabel(oldNaicsCode);
    });
  }

  /**
   * Get labels (e.g. Utah) from values (e.g. UT).
   *
   * See form-options-service.ts for details.
   * Instead of doing something more dynamic with `eval`, this list was short
   * enough to just code it and keep it more secure.
   *
   * @param string key - The borrower attribute or other list identifier.
   * @param string value - The value we want to exchange for a label.
   */
  getSelectLabel(key: string, value: string) {
    let list: Array<{label: string, value: string}> = [];
    switch(key) {
      case '676':
        list = this.formOptionsService.ba676();
        break;
      case '234':
        list = this.formOptionsService.ba234();
        break;
      case '230':
        list = this.formOptionsService.ba230();
        break;
      case '732':
        list = this.formOptionsService.ba732();
        break;
      case '86':
        list = this.formOptionsService.ba86();
        break;
      case 'states':
        list = this.formOptionsService.states();
        break;
      case 'yesNo':
        list = this.formOptionsService.yesNo();
        break;
    }

    // Now lookup the label from the value in the list.
    for(const item in list) {
      if (list[item].value == value) {
        return list[item].label;
      }
    }
  }

  /**
   * Use the naicsService to get the label for the new or updated code.
   */
  setNaicsLabel(oldNaicsCode: string | null = null): void {
    let code: string | undefined | number = this.currentBusiness?.borrowerValues['670'].value;

    // No change so exit.
    if (oldNaicsCode === code && this.naicsCodeLabel) return;
    if (code && code.toString().length === 6) {
      code = parseInt(code);
      this.naicsSearch.searchByCode(code).pipe(take(1)).subscribe({
        next: (res) => {
          // If we find a match, throw in the text
          if (res && 'data' in res && res.data.hasOwnProperty('code')) {
            this.naicsCodeLabel = res.data.title;
            // Required to update view after it finds the code label.
            this.cdRef.detectChanges();
          }
        },
        error: (err) => {
          // If the request errors, just let the text be blank
          this.naicsCodeLabel = '';
        }
      });
    } else {
      this.naicsCodeLabel = '';
    }
  }

  getUrl(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    } else {
      return url;
    }
  }

  protected _hasModifyAccess(business?: Business) {
    return (
      business?.id == this.id &&
      business?.accessLevel === BusinessAccessLevel.Modify
    );
  }
}
