import { computed, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

interface PageTitleData {
  title: string;
  secondaryTitle?: string;
  href?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PageTitleDataService {
  private _importDetailsActive = new BehaviorSubject<boolean>(false);
  public importDetailsActive$ = this._importDetailsActive.asObservable();

  private _pageTitle = signal('');
  private _secondaryPageTitle = signal('');
  private _pageTitleHref = signal('');

  public pageTitleData = computed(() => {
    return {
      title: this._pageTitle(),
      secondaryTitle: this._secondaryPageTitle(),
      href: this._pageTitleHref()
    }
  });

  setTitleData({title, secondaryTitle, href}: PageTitleData) {
    this._pageTitle.set(title);
    this._secondaryPageTitle.set(secondaryTitle ?? '');
    this._pageTitleHref.set(href ?? '');
  }


  setImportDetailsActive(hide: boolean) {
    this._importDetailsActive.next(hide);
  }

  getImportDetailsActive(): Observable<boolean> {
    return this.importDetailsActive$;
  }
}
