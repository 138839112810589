<div class="document-requirment-item mb-2">
    <div class="category-container flex justify-between items-center">
        <div>
            {{ documentRequirement.name }}
        </div>
        <div class="icons flex items-center">
            @if (documentRequirement.restrictions.includes('borrower')) {
                <!-- TODO: update with material symbols once installed mail_off_outlined -->
                <mat-icon font-set="material-symbols-outlined" class="mr-3 text-lendio-warm-gray-300 text-sm" [matTooltip]="'This document is not visible to the borrower.'">visibility_off_outlined</mat-icon>
            }
            <button (click)="remove()" mat-button class="text-lendio-blue-400 font-semibold">Remove</button>
        </div>
    </div>

    @if (documentRequirement.timeClassification === "month") {
        <mat-form-field appearance="outline" floatLabel="auto" class="w-full -mb-2 lendio-density-1 mt-2">
            <mat-label>Select month</mat-label>
            <mat-select (selectionChange)="updateMonthString($event)"
                name="monthsString-{{ i }}"
                data-cy="monthsSelect"
            >
            @for (month of last24Months; track month; let i = $index;) {
                <mat-option data-cy="month-options" [value]="month.parts">
                    {{ month.display }}
                </mat-option>
            }
            </mat-select>
        </mat-form-field>
    }

    @if (documentRequirement.timeClassification === 'year') {
        <mat-form-field appearance="outline" floatLabel="auto" class="w-full -mt-8 lendio-density-1 mt-3">
            <mat-label>Select year</mat-label>
            <mat-select (selectionChange)="updateYearString($event)"
                    name="yearsString-{{ i }}"
                    data-cy="yearsSelect"
        >
            @for (year of years; track year; let i = $index;) {
                <!-- // Do not show current year when category is tax return -->
                @if (!documentRequirement.id.match('taxReturn') || i > 0) {
                    <mat-option [value]="year">
                        {{ year }}
                    </mat-option>
                }
            }
            </mat-select>
        </mat-form-field>
    }
</div>
