import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  DOCUMENT,
  CurrencyPipe,
  DatePipe,
  PercentPipe,
  TitleCasePipe,
  DecimalPipe
} from "@angular/common";
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { StatusDisplayPipe } from 'src/app/pipes/status-display/status-display';
import { DealTypePipe } from 'src/app/pipes/deal-type/deal-type';
import { ProductTypePipe } from 'src/app/pipes/product-type/product-type';
import 'ag-grid-enterprise';
import { AgGridAngular } from "ag-grid-angular";
import {
  ChartMenuOptions,
  ColDef,
  ColumnApi,
  ColumnRowGroupChangedEvent,
  FirstDataRenderedEvent,
  GetLocaleTextParams,
  GridApi,
  GridReadyEvent,
  IAggFuncParams, ITooltipParams,
  MenuItemDef, ProcessCellForExportParams,
  RowSelectedEvent,
  SideBarDef,
  ToolPanelDef,
  ValueFormatterParams,
  ValueGetterParams
} from "ag-grid-community";
import { LocaleSettingService } from 'src/app/services/locale-setting-service';
import { ClientMilestonesComponent } from "src/app/components/clients/client-milestones/client-milestones.component";
import { LendioChartsTheme } from "src/assets/ag-grid/lendio-charts-theme";
import { OpportunityDetails } from 'src/app/interfaces/opportunity-details.model';
import { LocalStorageRefService } from "src/app/services/local-storage-ref.service";
import { LoginUser } from "src/app/interfaces/login-user.model";
import { DarkLaunchGetOpportunities } from "src/app/store/opportunities/opportunities.actions";
import { OpportunitiesState } from "src/app/store/opportunities/opportunities.state";
import {
  GetOpportunitiesGridState,
  SaveOpportunitiesGridState
} from "src/app/store/opportunitiesGrid/opportunities-grid.actions";
import { map, takeUntil, tap } from "rxjs/operators";
import {
  BooleanRendererComponent
} from "src/app/components/clients/grid-components/boolean-renderer/boolean-renderer.component";
import { AgChartOptions } from "ag-charts-community";
import { GroupHeaderComponent } from "src/app/components/clients/grid-components/group-header/group-header.component";
import { DealStatusDisplayPipe } from 'src/app/pipes/deal-status-display/deal-status-display.pipe';
import { PageTitleDataService } from '@app/app/services/page-title-data.service';
import { CommissionsTooltipComponent } from 'src/app/components/clients/grid-components/tooltips/commissions-tooltip/commissions-tooltip.component';
import { SaasFeaturesState } from "@app/app/store/saas-features/saas-features.state";
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { dateFilterComparator } from '@app/app/components/grid-components/grid-comparitors';

type StatusChartOption = { applicationStatus: string, total: number };
const DATE_COLUMN_IDS = [
  'leadSubmitted',
  'ownershipStart',
  'ownershipEnd',
  'applicationStarted',
  'appComplete',
  'offerReceived',
  'dealClosed',
];

@Component({
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  standalone: false
})
export class ClientsComponent  implements OnInit, OnDestroy {
  showClients = false;
  @Select(AuthState.user) currentUser$: Observable<AuthUser | undefined>;
  @Select(SaasFeaturesState.saasPermitted('dashboardPartnerBase')) hasPartnerDashboardFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.initialLoadComplete) sassFeaturesLoaded$: Observable<boolean>;
  @ViewChild(AgGridAngular) clientGrid!: AgGridAngular;
  public allowLeadSubmission: boolean;
  public opportunityDetails$: Observable<OpportunityDetails[] | null> = this.store.select(OpportunitiesState.darkLaunchOpportunities);
  public opportunitiesLoading$: Observable<boolean> = this.store.select(OpportunitiesState.loading);
  public gridStateSub: Subscription = new Subscription();
  public localStorageRef: any;
  public storedUser: LoginUser | undefined;
  public locale: string;
  public titleCasePipe: TitleCasePipe;
  public datePipe: DatePipe;
  public statusDisplayPipe: StatusDisplayPipe;
  public dealStatusDisplayPipe: DealStatusDisplayPipe;
  public dealTypeDisplayPipe: DealTypePipe;
  public productTypeDisplayPipe: ProductTypePipe;
  public percentPipe: PercentPipe;
  public currencyPipe: CurrencyPipe;
  public decimalPipe: DecimalPipe;
  public clientGridApi: GridApi | undefined;
  public clientGridColumnApi: ColumnApi | undefined;
  public leadsTotal = 0;
  public hideAdditionalCols = true;
  public lendioCustomChartsTheme = {lendioChartTheme: LendioChartsTheme};
  public clientGridChartThemes: string[] = ['lendioChartTheme', 'ag-vivid'];
  public clientGridChartData: any[] = [];
  private destroyed$ = new Subject<void>();
  private user: AuthUser | undefined;

  public rowGroupPanelShow: 'always' | 'never' | 'onlyWhenGrouping' | undefined = 'never';
  public clientGridIcons: any = {
    milestones: `<span class="ag-icon ag-icon-checkbox-checked"></span>`
  }

  public clientGridAggregators = {
    clientGridCommissionSum: (params: IAggFuncParams) => {
      let payoutSum = 0;
      params.values.forEach(value => payoutSum += value);
      return payoutSum;
    }
  }

  public clientTextFilterParams = {
    filterOptions: [
      'contains',
      'startsWith',
      'endsWith',
    ],
    defaultOption: 'contains',
    suppressAndOrCondition: true
  }

  public clientDateFilterParams = {
    comparator: dateFilterComparator,
    suppressAndOrCondition: true,
    filterOptions: [
      'equals',
      'inRange'
    ],
    defaultOption: 'equals',
    inRangeInclusive: true,
    inRangeFloatingFilterDateFormat: 'MM dd YYYY',
  }

  public clientNumberFilterParams = {
    filterOptions: [
      'equals',
      'greaterThan',
      'lessThan',
      'inRange'
    ],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
  }

  public defaultColumns: string[] = [
    'borrowerName',
    'leadReceivedAtTimestamp',
    'dealStatus',
    'dealType',
    'productType',
    'fundedAmount',
    'commissionAmount',
    // 'payoutAmount',
    'ownershipActive',
    'ownershipEndTimestamp'
  ]

  public clientGridColumnDefs: ColDef[] = [
    /*********************
     ** Default Columns **
     *********************/
    {
      headerName: 'Borrower ID',
      field: 'borrowerId',
      colId: 'borrowerId',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'series',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      enableRowGroup: true,
      minWidth: 300,
      maxWidth: 350,
      menuTabs: ['generalMenuTab'],
      valueGetter: (params: ValueGetterParams) => {
        if (params?.node?.group) {
          return params.node.allLeafChildren[0].data.borrowerId;
        } else {
          return params.data.borrowerId
        }
      },
      comparator: (valueA, valueB) => {
        const nameA: string = valueA.trim();
        const nameB: string = valueB.trim();
        return nameA?.localeCompare(nameB);
      }
    },
    {
      headerName: 'Borrower',
      field: 'borrowerName',
      colId: 'borrowerName',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      enableRowGroup: false,
      menuTabs: [],
      valueGetter: (params: ValueGetterParams) => {
        if (params?.node?.group) {
          return this.titleCasePipe?.transform(params.node.allLeafChildren[0].data.borrowerName);
        } else {
          return this.titleCasePipe?.transform(params.data.borrowerName);
        }
      },
      comparator: (valueA, valueB) => {
        const nameA: string = valueA.trim();
        const nameB: string = valueB.trim();
        return nameA?.localeCompare(nameB);
      }
    },
    {
      headerName: 'DBA',
      field: 'doingBusinessAs',
      colId: 'doingBusinessAs',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      enableRowGroup: false,
      menuTabs: [],
      valueGetter: (params: ValueGetterParams) => {
        if (params?.node?.group) {
          return this.titleCasePipe?.transform(params.node.allLeafChildren[0].data.doingBusinessAs);
        } else {
          return ''
        }
      },
      comparator: (valueA, valueB) => {
        const nameA: string = valueA.trim();
        const nameB: string = valueB.trim();
        return nameA?.localeCompare(nameB);
      }
    },
    {
      headerName: 'Ownership',
      field: 'ownershipActive',
      colId: 'ownershipActive',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      enableRowGroup: false,
      cellStyle: {textAlign: 'center'},
      minWidth: 110,
      maxWidth: 110,
      cellRenderer: BooleanRendererComponent,
      cellRendererParams: {
        key: 'ownershipActive',
        showInGroupRow: true,
        showInUngroupedRow: false
      }
    },
    {
      headerName: 'Ownership End',
      field: 'ownershipEndTimestamp',
      colId: 'ownershipEnd',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueGetter: (params: ValueGetterParams) => {
        return !params?.node?.group
          ? ''
          : params.node.allLeafChildren[0].data.ownershipEndTimestamp
      },
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : ''
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Lead Submitted',
      field: 'leadReceivedAtTimestamp',
      colId: 'leadSubmitted',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueGetter: (params: ValueGetterParams) => {
        if (params?.node?.group) {
          return params.node.allLeafChildren[0].data.leadReceivedAtTimestamp;
        } else {
          return ''
        }
      },
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : '';
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Status',
      field: 'dealStatus',
      colId: 'dealStatus',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'category',
      filter: 'agSetColumnFilter',
      enableRowGroup: false,
      valueGetter: (params: ValueGetterParams) => {
        return !params?.node?.group
          ? this.dealStatusDisplayPipe.transform(params.data.dealStatus)
          : this.statusDisplayPipe.transform(params.node.allLeafChildren[0].data.borrowerStage)
      },
      comparator: (valueA, valueB) => {
        return valueA?.localeCompare(valueB!);
      }
    },
    {
      headerName: 'Deal Type',
      field: 'dealType',
      colId: 'dealType',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'category',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          const dealType = this.dealTypeDisplayPipe.transform(params.data?.dealType);
          return dealType === ''
            ? undefined
            : dealType;
        }
      },
      comparator: (valueA, valueB) => {
        return valueA?.localeCompare(valueB);
      },
    },
    {
      headerName: 'Loan Product',
      field: 'productType',
      colId: 'loanProductCategory',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'category',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          const productType = this.productTypeDisplayPipe.transform(params.data?.productType);
          return productType === ''
            ? undefined
            : productType;
        }
      },
      comparator: (valueA, valueB) => {
        return valueA?.localeCompare(valueB);
      }
    },
    {
      headerName: 'Funded Amount',
      field: 'fundedAmount',
      colId: 'fundedAmount',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'series',
      filter: 'agNumberColumnFilter',
      filterParams: this.clientNumberFilterParams,
      enableRowGroup: false,
      sortingOrder: ['desc', 'asc', null],
      aggFunc: 'sum',
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          return Number(params.data.fundedAmount);
        }
      },
      valueFormatter: ({value, data}: ValueFormatterParams): string => {
        return value ? this.currencyPipe.transform(value)! : '';
      },
      comparator: (valueA, valueB) => {
        const currencyA = valueA ? parseFloat(valueA) : 0;
        const currencyB = valueB ? parseFloat(valueB) : 0;
        return currencyA - currencyB;
      },
    },
    {
      headerName: 'Commission',
      field: 'commissionAmount',
      colId: 'commissionAmount',
      hide: true,
      tooltipField: 'commissionAmount',
      tooltipComponent: CommissionsTooltipComponent,
      headerTooltip: 'commissionAmount',
      tooltipValueGetter: (params: ITooltipParams) =>  params.value,
      suppressColumnsToolPanel: true,
      chartDataType: 'series',
      filter: 'agNumberColumnFilter',
      filterParams: this.clientNumberFilterParams,
      enableRowGroup: false,
      aggFunc: 'clientGridCommissionSum',
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          return Number(params?.data?.commissionAmount)
        }
      },
      valueFormatter: ({value, data}: ValueFormatterParams): string => {
        return value ? this.currencyPipe.transform(value)! : '';
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const payoutA = parseFloat(nodeA.data?.commissionAmount);
        const payoutB = parseFloat(nodeB.data?.commissionAmount);
        return payoutA - payoutB;
      },
    },
    {
      headerName: 'Qualified',
      field: 'isQualified',
      colId: 'isQualified',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      enableRowGroup: false,
      minWidth: 98,
      maxWidth: 98,
      cellRenderer: BooleanRendererComponent,
      cellRendererParams: {
        key: 'isQualified',
        showInGroupRow: true,
        showInUngroupedRow: false
      }
    },
    /*
     * Values for this were still not correct.  Commenting out until further notice.
     */
    // {
    //   headerName: 'Partner Commission',
    //   field: 'payoutAmount',
    //   colId: 'payoutAmount',
    //   hide: true,
    //   tooltipField: 'payoutAmount',
    //   tooltipComponent: PayoutTooltipComponent,
    //   headerTooltip: 'payoutAmount',
    //   tooltipValueGetter: (params: ITooltipParams) =>  params.value,
    //   suppressColumnsToolPanel: true,
    //   chartDataType: 'series',
    //   filter: 'agNumberColumnFilter',
    //   filterParams: this.clientNumberFilterParams,
    //   enableRowGroup: false,
    //   aggFunc: 'clientGridCommissionSum',
    //   valueGetter: (params: ValueGetterParams) => {
    //     if (!params?.node?.group) {
    //       return Number(params?.data?.payoutAmount)
    //     }
    //   },
    //   valueFormatter: ({value, data}: ValueFormatterParams): string => {
    //     return value ? this.currencyPipe.transform(value)! : '';
    //   },
    //   comparator: (valueA, valueB, nodeA, nodeB) => {
    //     const payoutA = parseFloat(nodeA.data?.payoutAmount);
    //     const payoutB = parseFloat(nodeB.data?.payoutAmount);
    //     return payoutA - payoutB;
    //   },
    // },

    /*******************************
     ** Optional Columns (hidden) **
     *******************************/
    {
      headerName: 'Sales Rep',
      colId: 'salesRep',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          const formattedSalesRep = this.getFormattedSalesRep(params.data);
          return this.titleCasePipe?.transform(formattedSalesRep);
        }
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const lastNameA: string = nodeA.data?.agentLastName || '';
        const lastNameB: string = nodeB.data?.agentLastName || '';
        return lastNameA.localeCompare(lastNameB);
      }
    },
    {
      headerName: 'Ownership Start',
      field: 'ownershipStartTimestamp',
      colId: 'ownershipStart',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueGetter: (params: ValueGetterParams) => {
        return !params?.node?.group
          ? ''
          : params.node.allLeafChildren[0].data.ownershipStartTimestamp
      },
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : ''
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Marketing Campaign',
      field: 'campaign',
      colId: 'marketingCampaign',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'category',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      menuTabs: ['generalMenuTab'],
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          const campaign = this.titleCasePipe.transform(params.data?.campaign);
          return campaign ? campaign : undefined;
        }
      },
      valueFormatter: ({value, data}: ValueFormatterParams): string => {
        return this.titleCasePipe.transform(value);
      },
      comparator: (valueA, valueB) => {
        return valueA?.localeCompare(valueB);
      },
    },
    {
      headerName: 'Ad Group',
      field: 'adGroup',
      colId: 'adGroup',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'category',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      menuTabs: ['generalMenuTab'],
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          const adGroup = this.titleCasePipe.transform(params.data?.adGroup);
          return adGroup ? adGroup : undefined;
        }
      },
      valueFormatter: ({value, data}: ValueFormatterParams): string => {
        return this.titleCasePipe.transform(value);
      },
      comparator: (valueA, valueB) => {
        return valueA?.localeCompare(valueB);
      },
    },
    {
      headerName: 'Landing Page',
      field: 'landingPage',
      colId: 'landingPage',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      enableRowGroup: false,
      menuTabs: [],
      valueGetter: (params: ValueGetterParams) => {
        if (!params?.node?.group) {
          return this.titleCasePipe?.transform(params.data?.landingPage);
        }
      },
      comparator: (valueA, valueB) => {
        const nameA: string = valueA.trim();
        const nameB: string = valueB.trim();
        return nameA?.localeCompare(nameB);
      }
    },
    {
      headerName: 'Application Started',
      field: 'applicationStartedAtTimestamp',
      colId: 'applicationStarted',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : '';
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Application Completed',
      field: 'applicationCompletedAtTimestamp',
      colId: 'appComplete',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : '';
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Offer Received',
      field: 'offerReceivedAtTimestamp',
      colId: 'offerReceived',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : '';
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },
    {
      headerName: 'Deal Closed',
      field: 'dealClosedAtTimestamp',
      colId: 'dealClosed',
      hide: true,
      suppressColumnsToolPanel: true,
      chartDataType: 'excluded',
      filter: 'agDateColumnFilter',
      filterParams: this.clientDateFilterParams,
      sortingOrder: ['asc', 'desc', null],
      minWidth: 190,
      valueFormatter: ({value, data}: ValueFormatterParams) => {
        return value
          ? this.datePipe.transform((value * 1000), 'MMM dd, yyyy')!
          : '';
      },
      comparator: (valueA, valueB) => {
        return valueA - valueB;
      },
    },

    /*********************************
     ** Internal Columns (employee) **
     *********************************/
    {
      headerName: 'Affiliate Id (internal)',
      hide: true,
      suppressColumnsToolPanel: true,
      wrapHeaderText: true,
      field: 'affiliateId',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      minWidth: 150,
      maxWidth: 150
    },
    {
      headerName: 'Marketing Id (internal)',
      hide: true,
      suppressColumnsToolPanel: true,
      wrapHeaderText: true,
      field: 'marketingId',
      filter: 'agTextColumnFilter',
      filterParams: this.clientTextFilterParams,
      minWidth: 150,
      maxWidth: 150
    },
  ];

  public clientGridDefaultColDef: ColDef = {
    flex: 1,
    minWidth: 180,
    floatingFilter: true,
    filterParams: {
      buttons: ['apply', 'clear', 'reset'],
      readonly: false,
      closeOnApply: true
    },
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    enableValue: true,
    menuTabs: [],
  };

  public clientGridAutoGroupColumnDef: ColDef = {
    minWidth: 300,
    field: 'borrowerId',
    headerComponent: GroupHeaderComponent,
    filter: 'agTextColumnFilter',
    sortable: true,
    enableValue: true,
    headerName: 'Borrower ID',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true,
    },
    tooltipValueGetter: () => '',
  };

  public clientColumnsToolPanelDef: ToolPanelDef = {
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    iconKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    toolPanelParams: {
      suppressPivotMode: true,
      suppressPivots: true,
      suppressValues: true,
      suppressRowGroups: true,
      suppressRowDrag: true,
      suppressColumnDragHandle: true,
      suppressIconGrip: true,
      suppressSelectColumnDragHandle: true,
    }
  };

  public milestonesToolPanelDef: ToolPanelDef = {
    id: 'milestones',
    labelDefault: 'Milestones',
    labelKey: 'milestones',
    iconKey: 'milestones',
    toolPanel: ClientMilestonesComponent,
    minWidth: 300,
    maxWidth: 300,
    width: 300
  };

  public clientGridSideBarDef: SideBarDef = {
    toolPanels: [
      this.clientColumnsToolPanelDef,
      this.milestonesToolPanelDef
    ]
  }

  public clientGridOptions = {
    popupParent: this._document.body,
    animateRows: true,
    groupRowRenderer: 'agGroupCellRenderer',
    rowGroupPanelShow: this.rowGroupPanelShow,
    suppressAggFuncInHeader: true,
    suppressCellFocus: true,
    enableCharts: false,
    enableCellTextSelection: true,
    enableRangeSelection: true,
    groupDefaultExpanded: 1,
    tooltipInteraction: true,
    tooltipShowDelay: 250,
  };

  public getClientGridLocaleText: (params: GetLocaleTextParams) => string = (
    params: GetLocaleTextParams
  ) => {
    switch (params.key) {
      case 'rowGroupColumnsEmptyMessage':
        return 'Drag columns here to create groups';
      /*
       * This switch can be used to change any of the default
       * text in the grid (including headers/instructions/etc)
       */
      default:
        return params.defaultValue;
    }
  }

  public clientGridChartOptions: AgChartOptions = {
    data: this.clientGridChartData,
    padding: {
      right: 60,
    },
    theme: {
      baseTheme: 'ag-vivid',
      palette: {
        fills: [
          '#29b6f6',
          '#0082FF',
          '#DBEAFC',
          '#004AB7',
        ],
        strokes: ['#FFFFFF'],
      },
      overrides: {
        bar: {
          background: {
            visible: false,
          },
          legend: {
            enabled: false
          },
          axes: {
            number: {
              label: {
                fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
                rotation: 0,
              },
              tick: {
                width: 0,
              }
            },
            category: {
              line: {
                width: 0,
              },
              label: {
                fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif',
                rotation: 0,
                padding: 8,
              },
              tick: {
                width: 0
              },
            },
          },
          series: {
            highlightStyle: {
              item: {
                fill: '#80D8FF',
                stroke: '#80D8FF',
                fillOpacity: 0.8,
              }
            },
            tooltip: {
              renderer: (params: any) => {
                const percentage: number = ((Number(params.datum.total) / Number(this.leadsTotal) * 100));
                const formattedTotal = this.decimalPipe.transform(params.datum.total, '1.0-0')!
                const content = percentage >= 1.00 ? `${formattedTotal} (${percentage.toFixed(0)}%)` : `${formattedTotal} (< 1%)`;
                const title = params.datum.applicationStatus;
                return {content, title};
              }
            }
          },
        }
      }
    },
    tooltip: {
      class: 'funnel-chart-tooltip'
    },
    series: [
      {
        type: 'bar',
        xKey: 'applicationStatus',
        yKey: 'total',
      }
    ],

  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private store: Store,
    private localeSettingService: LocaleSettingService,
    private localStorageService: LocalStorageRefService,
    private _pageService: PageTitleDataService,
    private _formOptionsService: FormOptionsService,
  ) {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.user = user;
    });
    if (!this.user) {
      // upon authorization conditionally route users
      // Band-aid fix to prevent unsupported users from getting to this route, until clients is supported for turn down partners
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.router.navigateByUrl(localStorage.getItem('Home'));
      return;
    }

    this.allowLeadSubmission = true;
    this.localStorageRef = this.localStorageService.localStorage;
    this.locale = this.localeSettingService.getLocale();
    this.titleCasePipe = new TitleCasePipe();
    this.datePipe = new DatePipe(this.locale);
    this.statusDisplayPipe = new StatusDisplayPipe();
    this.dealStatusDisplayPipe = new DealStatusDisplayPipe();
    this.dealTypeDisplayPipe = new DealTypePipe();
    this.productTypeDisplayPipe = new ProductTypePipe();
    this.percentPipe = new PercentPipe(this.locale);
    this.currencyPipe = new CurrencyPipe(this.locale);
    this.decimalPipe = new DecimalPipe(this.locale);
    this.storedUser = JSON.parse(this.localStorageRef.getItem('PortalUser'));

    if (this.storedUser?.email?.includes('@lendio.com')) {
      this.hideAdditionalCols = false;
    }
  }

  ngOnInit(): void {
    this.hasPartnerDashboardFeature$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (feature) => {
        if (feature) {
          this.router.navigateByUrl('partner-dashboard');
        } else{
          setTimeout(() =>  this.showClients = true, 500);
        }
      }
    )

    this._pageService.setTitleData({title: 'Dashboard'});
    this.allowLeadSubmission = (this.user && (!Array.isArray(this.user.permissionsList) || this.user.permissionsList.length > 0) && this.user.permissionsList?.includes('partnerLeadSubmission')) || false;
    this._formOptionsService.setHubspotEnabled(this.allowLeadSubmission);
    this.store.dispatch(new DarkLaunchGetOpportunities());
    this.opportunityDetails$.pipe(
      takeUntil(this.destroyed$),
      map((data: OpportunityDetails[]) => {
        if (data) {
          this.leadsTotal = data.length;
          data.forEach(opportunity => {
            if (opportunity.dealId !== null) {
              this.leadsTotal += 1;
            }
          });

          const totalDealInProgress = data.filter(opportunity => opportunity.applicationStatus === 'dealInProgress').length;
          const totalOnHold = data.filter(opportunity => opportunity.applicationStatus === 'onHold').length;
          const totalAttempted = data.filter(opportunity => opportunity.applicationStatus === 'attempted').length;
          const totalFunded = data.filter(opportunity => opportunity.applicationStatus === 'funded').length;
          const totalNoAttempt = data.filter(opportunity => opportunity.applicationStatus === 'noAttempt').length;
          const totalInContact = data.filter(opportunity => opportunity.applicationStatus === 'inContact').length;

          this.clientGridChartOptions?.data?.push(
            {applicationStatus: 'Deal In Progress', total: totalDealInProgress},
            {applicationStatus: 'On Hold', total: totalOnHold},
            {applicationStatus: 'Attempted', total: totalAttempted},
            {applicationStatus: 'Funded', total: totalFunded},
            {applicationStatus: 'No Attempt', total: totalNoAttempt},
            {applicationStatus: 'In Contact', total: totalInContact}
          );
          this.clientGridChartOptions?.data?.sort((a: StatusChartOption, b: StatusChartOption) => (b.total - a.total));
        }
      })
    ).subscribe();


  }

  canDeactivate(): Observable<boolean> | boolean {
    const columnState = this.clientGridColumnApi?.getColumnState();
    const columnGroupState = this.clientGridColumnApi?.getColumnGroupState();
    const filterState = this.clientGridApi?.getFilterModel();
    this.store.dispatch(new SaveOpportunitiesGridState({columnState, columnGroupState, filterState}));

    // if we want to prompt them to save
    /*
      const dialogRef = this.confirmDialog.open(ConfirmGridDeactivateComponent, {
       width: '400px',
     })
     return dialogRef.afterClosed();
     */
    return true;
  }

  onClientGridReady(params: GridReadyEvent): void {
    this.clientGridApi = params.api;
    this.clientGridColumnApi = params.columnApi;
    this.opportunityDetails$.pipe(
      takeUntil(this.destroyed$),
      tap((data: OpportunityDetails[]) => {
        if(!data?.length) {
          return;
        }
        const keys = Object.keys(data[0])
        keys.forEach(key => {
          const column = this.clientGridColumnDefs.find(col => col.field === key)
          if (column) {
            column.hide = !this.defaultColumns.some(i => i === column.field)
            column.suppressColumnsToolPanel = false
          }
        })
        // if borrowerName key exists, sort on borrowerName
        // if borrowerName key does not exist, sort on borrowerId
        this.clientGridColumnApi?.applyColumnState({
          state: [
            {
              colId: keys.some(k => k === 'borrowerId') ? 'borrowerId' : 'borrowerName',
              rowGroupIndex: 0
            }
          ],
          defaultState: {
            rowGroup: false
          }
        })
        params.api.setColumnDefs(this.clientGridColumnDefs);
      })
    ).subscribe();
  }

  onClientFirstData(event: FirstDataRenderedEvent): void {
    event.api.setColumnDefs(this.clientGridColumnDefs);
    event.api.sizeColumnsToFit();

    this.gridStateSub = this.store.dispatch(new GetOpportunitiesGridState()).subscribe((state: any) => {
      const clientGridState = !!state && state.opportunitiesGridState;
      if (clientGridState?.columnGroupState) {
        event.columnApi.setColumnGroupState(clientGridState.columnGroupState);
      }
      if (clientGridState?.columnState) {
        event.columnApi.applyColumnState({state: clientGridState.columnState});
      }
      if (clientGridState?.filterState) {
        event.api.setFilterModel(clientGridState.filterState);
      }
    });
  }

  getClientGridMainMenuItems(): (string | MenuItemDef)[] {
    return ['rowGroup'];
  }

  getClientGridChartToolbarItems(): ChartMenuOptions[] {
    return ['chartDownload', 'chartData', 'chartSettings'];
  }

  getFormattedSalesRep(client: any): string {
    const undesirableValues = [null, 'null', undefined, ''];
    const first = client?.agentFirstName;
    const last = client?.agentLastName;
    const isDesirableFirst = first && !undesirableValues.includes(first);
    const isDesirableLast = last && !undesirableValues.includes(last);

    switch (!!client) {
      case (isDesirableFirst && isDesirableLast):
        return `${first} ${last}`;

      case (isDesirableFirst && !isDesirableLast):
        return first;

      case (!isDesirableFirst && isDesirableLast):
        return last;

      case false:  // no client
        return '';

      default:
        return '';
    }
  }

  onClientGridRowSelected(event: RowSelectedEvent): void {
    event.api.openToolPanel('milestones');
  }

  onClientGridGroupChange(event: ColumnRowGroupChangedEvent): void {
    const firstGroup = event.columns && event.columns[0];
    if (firstGroup && firstGroup.getColId() === 'borrower') {
      event.api.forEachNode((node: any) => {
        node.setExpanded(true);
      });
    }
    this.sizeColumnsToFit();
  }

  onClientGridSizeChange(): void {
    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    this.clientGridApi?.sizeColumnsToFit();
  }

  private _formatDateForExport(params: ProcessCellForExportParams) {
    if (DATE_COLUMN_IDS.includes(params.column.getColId()) && params.value) {
      params.value = this.datePipe.transform((params.value * 1000), 'MMM dd, y, h:mm a');
    }
    return params.value;
  }

  btnExportToExcel(): void {
    this.clientGridApi!.exportDataAsExcel({
      processCellCallback: (params) => this._formatDateForExport(params)
    });
  }

  btnExportToCsv(): void {
    this.clientGridApi!.exportDataAsCsv({
      processCellCallback: (params) => this._formatDateForExport(params)
    });
  }


  ngOnDestroy(): void {
    this.gridStateSub.unsubscribe();
    this.destroyed$.next();
  }
}
