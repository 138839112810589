import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { EarlyAccessFeatureService } from "@app/app/components/settings/early-access/early-access-feature.service";
import { MatchMenuWidthDirective } from '@app/app/components/lendio-angular-material-theme/match-menu-width.directive';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SlideAnimationDirective } from '@app/app/animations/slide/slide-animation.directive';
import { MatMenuModule } from '@angular/material/menu';
import { SaasNewFeatureBadgeComponent } from '../saas-new-feature-badge/saas-new-feature-badge.component';
import { slideAnimation } from '@app/app/animations/slide/slide.animation';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  standalone: true,
  imports: [
    MatchMenuWidthDirective,
    CommonModule,
    MatIconModule,
    SlideAnimationDirective,
    MatMenuModule,
    SaasNewFeatureBadgeComponent,
  ],
  selector: 'app-sidenav-user',
  templateUrl: './sidenav-user.component.html',
  styleUrls: ['./sidenav-user.component.scss'],
  animations: [slideAnimation]
})
export class SidenavUserComponent {
  @Input() collapsed = false;
  @Input() navMenuCollapsed = false;
  @Input() userFullName!: string;
  @Input() institutionName!: string;
  @Input() isSbaSpecialist: boolean;
  @Input() organizationName!: string;
  @Output() userLogout: EventEmitter<any> = new EventEmitter();
  @Output() userSettings: EventEmitter<any> = new EventEmitter();

  saasFeatureBadgeEnabled = computed(() => this._earlyAccessService.earlyAccessShowBadge());
  navV2 = this._store.selectSignal(AuthState.userHasPermission('NavigationV2DarkLaunch'));

  constructor(
    private _earlyAccessService: EarlyAccessFeatureService,
    private _store: Store
  ) { }

  selectLogout(): void {
    this.userLogout.emit();
  }

  selectSettings(): void {
    this.userSettings.emit();
  }
}
