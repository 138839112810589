import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'camelToSentence'
})
export class CamelToSentencePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const withSpaces = value.replace(/([A-Z])/g, ' $1');
    return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
  }

  static format(value: string): string {
    if (!value) {
      return '';
    }
    const withSpaces = value.replace(/([A-Z])/g, ' $1');
    return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
  }
}
