import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, computed, Inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DebtScheduleItem } from '@app/app/interfaces/debt-schedule-item.struct';
import { DebtScheduleService } from '@app/app/services/dscr/debt-schedule.service';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { CreateDebtScheduleItem, UpdateDebtScheduleItem } from '@app/app/store/debt-schedule/debt-schedule.actions';
import { Store } from '@ngxs/store';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-debt-schedule-item-dialog',
  standalone: true,
  templateUrl: 'debt-schedule-item-dialog.component.html',
  styleUrl: '../dscr-tab.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
    NgxMaskDirective,
  ],
  providers: [provideNgxMask()]
})
export class DebtScheduleItemDialogComponent {
  borrowerId = toSignal(this.store.select(BusinessState.businessId));
  editMode = computed(() => !!this.data?.id);
  titleVariant = computed(() => {
    return this.editMode() ? 'Edit' : 'Create';
  });
  formGroup: FormGroup;
  debtTypeOptions = DebtScheduleService.debtTypeOptions;
  frequencyOptions = DebtScheduleService.paymentFrequencyOptions;

  constructor(
    private store: Store,
    private dialogRef: DialogRef<DebtScheduleItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: DebtScheduleItem,
  ) {
    this.formGroup = new FormGroup({
      lenderName: new FormControl(this.data?.lenderName, [Validators.required]),
      debtType: new FormControl(this.data?.debtType, [Validators.required]),
      loanStartDate: new FormControl(this.data?.loanStartDate, [Validators.required]),
      paymentFrequency: new FormControl(this.data?.paymentFrequency || 'monthly', [Validators.required]),
      originalBalance: new FormControl(this.data?.originalBalance, [Validators.required]),
      currentBalance: new FormControl(this.data?.currentBalance, [Validators.required]),
      paymentAmount: new FormControl(this.data?.paymentAmount, [Validators.required]),
      isDebtConsolidation: new FormControl(this.data?.isDebtConsolidation || false),
      isSBALoan: new FormControl(this.data?.isSBALoan || false),
      term: new FormControl(this.data?.term, [Validators.required]),
      interestRate: new FormControl(this.data?.interestRate, [Validators.required]),
    });
  }

  save() {
    const action = this.editMode() ? UpdateDebtScheduleItem : CreateDebtScheduleItem;
    const borrowerId = this.borrowerId() as number;
    const id = (this.data?.id as number) || undefined;
    const value = {
      id,
      ...this.formGroup.value,
      borrowerId,
    } as DebtScheduleItem;
    this.store.dispatch(new action( borrowerId, value));
    this.dialogRef.close();
  }
}
