import {
  CurrencyPipe,
  formatCurrency,
  formatDate,
  formatPercent,
  KeyValuePipe,
  NgClass
} from '@angular/common';
import { Component, computed, Input, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatPrefix } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { LoadersModule } from '@app/app/components/loaders/loaders.module';
import { CamelToSentencePipe } from '@app/app/pipes/camel-to-sentence.pipe';
import { CapitalizePipePipeModule } from '@app/app/pipes/capitalize/capitalize-pipe.module';
import { DebtScheduleState } from '@app/app/store/debt-schedule/debt-schedule.state';
import { ProfitLossState } from '@app/app/store/profit-loss/profit-loss.state';
import { Store } from '@ngxs/store';
import { DebtScheduleItemDialogComponent } from './debt-schedule-item-dialog/debt-schedule-item.dialog.component';
import { DscrEditDialogComponent } from './dscr-edit-dialog/dscr-edit-dialog.component';
import { GridTopBottomIdentifier } from './grid-top-bottom-identifier.directive';

@Component({
  selector: 'app-dscr-tab',
  imports: [
    MatButtonToggleModule,
    MatIconModule,
    CurrencyPipe,
    GridTopBottomIdentifier,
    MatIconModule,
    MatButtonModule,
    KeyValuePipe,
    CamelToSentencePipe,
    CapitalizePipePipeModule,
    LoadersModule,
    MatPrefix,
    NgClass
  ],
  templateUrl: './dscr-tab.component.html',
  styleUrl: './dscr-tab.component.scss'
})
export class DscrTabComponent {
  @Input({required: true}) businessId;
  debtScheduleLoading = this._store.selectSignal(DebtScheduleState.loading);
  profitLossLoading = this._store.selectSignal(ProfitLossState.loading);
  dscrLoading = computed(() => this.debtScheduleLoading() || this.profitLossLoading());
  debtScheduleItems = this._store.selectSignal(DebtScheduleState.debtScheduleItems);
  debtScheduleItemsData = computed(() => {
    const items = this.debtScheduleItems();
    return items.map(item => {
      const {
        isDebtConsolidation,
        isSBALoan,
        created,
        modified,
        loanStartDate,
        currentBalance,
        paymentAmount,
        paymentFrequency,
        term,
        originalBalance,
        interestRate,
        debtType,
        borrowerId,
        deleted,
        ...remaining
      } = item;

      const formattedCreated = created ? formatDate(created, 'shortDate', 'en-US') : '-';
      const formattedModified = modified ? formatDate(modified, 'short', 'en-US') : '-';
      const formattedLoanStartDate = loanStartDate ? formatDate(loanStartDate, 'shortDate', 'en-US') : '-';
      const formattedCurrentBalance = currentBalance ? formatCurrency(Number(currentBalance), 'en-US', '$') : '-';
      const formattedPaymentAmount = paymentAmount ? formatCurrency(Number(paymentAmount), 'en-US', '$') : '-';
      const formattedOriginalBalance = originalBalance ? formatCurrency(Number(originalBalance), 'en-US', '$') : '-';
      const formattedInterestRate = interestRate ? formatPercent(interestRate / 100, 'en-US', '1.2-2') : '-';
      const formattedDebtType = CamelToSentencePipe.format(debtType);
      const formattedPaymentFrequency = CamelToSentencePipe.format(paymentFrequency);

      return   {
        ...remaining,
        created: formattedCreated,
        currentBalance: formattedCurrentBalance,
        debtConsolidation: isDebtConsolidation ? 'Yes' : 'No',
        debtType: formattedDebtType,
        interestRate: formattedInterestRate,
        loanStartDate: formattedLoanStartDate,
        modified: formattedModified,
        originalBalance: formattedOriginalBalance,
        paymentAmount: formattedPaymentAmount,
        paymentFrequency: formattedPaymentFrequency,
        sbaLoan: isSBALoan ? 'Yes' : 'No',
        term: term ? `${term} months` : '-'
      };
    });
  })

  currentYear = computed(() => new Date().getFullYear());
  years = computed(() => {
    const currentYear = this.currentYear();
    return Array.from({length: 4}, (v, i) => currentYear - i);
  });
  viewYear = signal(this.currentYear());

  personalTaxData = computed(() => {
      return this._store.selectSignal(
        ProfitLossState.profitLossStatementItemsForContext('personal', this.viewYear())
      )();
  });

  businessTaxData = computed(() => {
    return this._store.selectSignal(
      ProfitLossState.profitLossStatementItemsForContext('business', this.viewYear())
    )();
  });

  hasDscrYearData = computed(() => {
    return(
      this.businessTaxData()?.length ||
      this.personalTaxData()?.length ||
      (this.viewYear() === this.currentYear() && this.debtScheduleItemsData()?.length)
    )
  });

  constructor(
    private _dialog: MatDialog,
    private _store: Store,
  ) { }

  selectYear(year: number) {
    this.viewYear.set(year);
  }

  openEditDialog() {
    this._dialog.open(DscrEditDialogComponent, {
      width: '674px',
      maxHeight: '720px',
      autoFocus: false,
      data: {
        personalTaxData: this.personalTaxData(),
        businessTaxData: this.businessTaxData(),
        year: this.viewYear(),
        isCurrentYear: this.viewYear() === this.currentYear()
      },
    });
  }

  editDebtScheduleItem(itemId: number) {
    const items = this.debtScheduleItems();
    const debtScheduleItem = items.find(item => item.id === itemId);
    debtScheduleItem && this._dialog.open(DebtScheduleItemDialogComponent, {
      width: '700px',
      maxHeight: '720px',
      autoFocus: false,
      data: {
        ...debtScheduleItem
      },
    });
  }

  newDebtScheduleItem() {
    this._dialog.open(DebtScheduleItemDialogComponent, {
      width: '674px',
      maxHeight: '720px',
      autoFocus: false,
    });
  }
}
