import { Injectable } from '@angular/core';
import { ProfitLossStatementItem } from '@app/app/interfaces/profit-loss-statement-item.struct';
import { ProfitLossService } from '@app/app/services/dscr/profit-loss.service';
import { upsertItem } from '@app/app/store/ngxs-operators/upsert.operator';
import {
  GetProfitLossStatementItems,
  UpsertProfitLossStatementItems,
} from '@app/app/store/profit-loss/profit-loss.actions';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { compose, patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface ProfitLossStateModel {
  loading: boolean;
  profitLossStatementItems: ProfitLossStatementItem[];
}

@State<Partial<ProfitLossStateModel>>({
  name: 'ProfitLoss',
  defaults: {
    loading: false,
    profitLossStatementItems: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ProfitLossState {

  @Selector()
  static profitLossStatementItems(state: ProfitLossStateModel): ProfitLossStatementItem[] {
    return state.profitLossStatementItems;
  }

  @Selector()
  static loading(state: Partial<ProfitLossStateModel>) {
    return state.loading;
  }

  /**
   * Factory to get profitLoss statement items filtered by type and year.
   * @param type The type of items (i.e... 'personal' or 'business')
   * @param year The year to filter by
   * @returns A selector function that returns filtered ProfitLoss items
   **/
  static profitLossStatementItemsForContext(type: string, year: number) {
    return createSelector(
      [ProfitLossState.profitLossStatementItems],
      (items: ProfitLossStatementItem[]) => {
        return (items ?? []).filter((item) => item.year === year && item.type === type);
      }
    );
  }

  /**
   * Factory to get profitLoss statement items filtered by year.
   * @param year The year to filter by
   * @returnsA selector function that returns filtered ProfitLoss items
   **/
  static profitLossStatementItemsByYear(year: number) {
    return createSelector(
      [ProfitLossState.profitLossStatementItems],
      (items: ProfitLossStatementItem[]) => {
        return (items ?? []).filter((item) => item.year === year);
      }
    );
  }

  constructor(private profitLossService: ProfitLossService) { }

  @Action(GetProfitLossStatementItems)
  GetProfitLossStatementItems(
    ctx: StateContext<ProfitLossStateModel>,
    { borrowerId }: GetProfitLossStatementItems,
  ) {
    ctx.patchState({loading: true});
    return this.profitLossService.getProfitLossStatementItems(borrowerId)
      .pipe(
        catchError(err => {
          ctx.patchState({loading: false});
          throw err;
        }),
        tap(({ data })  => {
          ctx.patchState({
            loading: false,
            profitLossStatementItems: data,
          });
        }),
      );
  }

  @Action(UpsertProfitLossStatementItems)
  UpsertProfitLossStatementItems(
    ctx: StateContext<ProfitLossStateModel>,
    { borrowerId, profitLossStatementItems }: UpsertProfitLossStatementItems,
  ) {
    ctx.patchState({loading: true});
    return this.profitLossService.upsertProfitLossStatementItems(borrowerId, profitLossStatementItems)
      .pipe(
        catchError(err => {
          ctx.patchState({loading: false});
          throw err;
        }),
        tap(({data}) => {
          const upsert = data!.map(
            item => upsertItem<ProfitLossStatementItem>(
              existing => {
                return existing.profitLossStatementFieldId === item.profitLossStatementFieldId
                  && existing.year === item.year;
              }, item),
          );
          ctx.setState(patch({
            loading: false,
            profitLossStatementItems: compose(...upsert),
          }));
        }),
      );
  }
}
