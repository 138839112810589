import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngxs/store';
import { Hijack } from '@app/app/store/auth/auth.actions';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  standalone: false,
  selector: 'app-hijack',
  templateUrl: './hijack.component.html',
  styleUrls: ['./hijack.component.scss'],
  animations: [
    trigger('expandInput',
      [
        state('collapsed', style({ width: '0' })),
        state('expanded', style({ width: '300px' })),
        transition('collapsed => expanded', animate('350ms cubic-bezier(.4, .22, .31, 1.01)')),
        transition('expanded => collapsed', animate('350ms cubic-bezier(.4, .22, .31, 1.01)'))
      ]),
  ]
})
export class HijackComponent implements OnChanges {
  @Input() searchResults: any;
  @Input() users: any;
  @Output() searchTerm = new EventEmitter<string>();
  @Output() partnerSelect = new EventEmitter<{ id: number, type: string }>();

  @ViewChild('partnersTrigger') partnersTrigger: MatMenuTrigger;
  @ViewChild('usersTrigger') usersTrigger: MatMenuTrigger;

  navV2 = this.store.selectSignal(AuthState.userHasPermission('NavigationV2DarkLaunch'));
  inputText = '';
  retrievingUsers = false;
  retrievedUsers = false;
  hijackSearchVisible = false;

  constructor(private store: Store) { }

  clearSearchField(): void {
    this.inputText = '';
  }

  closeSearchField(): void {
    this.hijackSearchVisible = false;
  }

  toggleSearchField(): void {
    this.hijackSearchVisible = !this.hijackSearchVisible;
  }

  onInFieldCloseButton(): void {
    return this.inputText.length ? this.clearSearchField() : this.closeSearchField();
  }

  search() {
    this.searchTerm.emit(this.inputText);
  }

  partnerHovered(partner: { id: number, type: string }) {
    this.retrievedUsers = false;
    this.getUsers(partner);
  }

  getUsers(partner: { id: number, type: string }) {
    this.retrievingUsers = true;
    this.partnerSelect.emit(partner);
  }

  hijack(userId: number) {
    this.store.dispatch(new Hijack(userId))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('searchResults')) {
      if (this.inputText !== '') {
        this.partnersTrigger.openMenu();
      }
    }

    if (changes.users && changes.users.currentValue !== null) {
      this.retrievingUsers = false;
      this.retrievedUsers = true;
    }
  }

}
